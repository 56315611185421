import React, { useState, useEffect, useRef } from 'react';
import { MaskedTextField } from 'office-ui-fabric-react';
import IMes from '../../../../interfaces/IMes';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import ISemana from '../../../../interfaces/ISemana';
import moment from 'moment';

interface Resource {
  activo : boolean
  fechaDesvinculacion : string
}

interface Props {
  mes: IMes;
  semana: ISemana;
  column: IColumn;
  onChange?: (e: any) => void;
  enable?: boolean;
  defaultValue: number;
  reference: (ref: React.Ref<any>) => void;
  focusNext: () => void;
  focusPrevious: () => void;
  suggestedValue: number;
  noCertificable: boolean;
  resource : Resource;
  onValueChange?: (value: boolean) => void;
}

const DEFAULT_MASK = '__:__';

export const InputHoras = (props: Props): JSX.Element => {
  const [value, setValue] = useState(DEFAULT_MASK);
  const [isFocused, setIsFocused] = useState(false);
  const [lastValue, setLastValue] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    props.reference(ref);
  }, []);

  useEffect(() => {
    if (isFocused) {
      ref.current._textField.current.setSelectionEnd(5);
      ref.current._textField.current.setSelectionStart(5);
    }
  }, [isFocused]);

  useEffect(() => {
    if (props.defaultValue !== null) {
      const defVal = Number(props.defaultValue);
      if (Number.isNaN(defVal) === false && defVal >= 0) {
        setValue(getMaskedNumber(defVal));
      }
    }
  }, [props.defaultValue]);

  const getMaskedNumber = (val) => {
    let defHoras: any = Math.floor(val);
    let defMin: any = Math.round((val - defHoras) * 60);
    if (defHoras < 10) defHoras = '0' + defHoras;
    if (defMin < 10) defMin = '0' + defMin;
    return defHoras + ':' + defMin;
  };

  let enable = false;
  let disabledTitle = 'No se puede certificar, porque no pertenece al mes de ' + props.mes.value;
  switch (props.column.key) {
    case 'lunes':
      if (props.semana.primerDia.month() === props.mes.key) {
        if (!props.resource.activo && props.semana.primerDia.isAfter(props.resource.fechaDesvinculacion)) {
          disabledTitle = 'No puedes certificar horas de un recurso despues de su fecha de desvinculacion';
        } else if (!props.semana.primerDia.isAfter(moment())) {
          enable = true;
        } else {
          disabledTitle = 'No puedes certificar una fecha que no ha transcurrido';
        }
      }
      break;
    case 'martes':
      if (props.semana.primerDia.clone().add(1, 'day').month() === props.mes.key) {
        if (!props.resource.activo && props.semana.primerDia.clone().add(1, 'day').isAfter(props.resource.fechaDesvinculacion)) {
          disabledTitle = 'No puedes certificar horas de un recurso despues de su fecha de desvinculacion';
        } else if (!props.semana.primerDia.clone().add(1, 'day').isAfter(moment())) {
          enable = true;
        } else {
          disabledTitle = 'No puedes certificar una fecha que no ha transcurrido';
        }
      }
      break;
    case 'miercoles':
      if (props.semana.primerDia.clone().add(2, 'day').month() === props.mes.key) {
        if (!props.resource.activo && props.semana.primerDia.clone().add(2, 'day').isAfter(props.resource.fechaDesvinculacion)) {
          disabledTitle = 'No puedes certificar horas de un recurso despues de su fecha de desvinculacion';
        } else if (!props.semana.primerDia.clone().add(2, 'day').isAfter(moment())) {
          enable = true;
        } else {
          disabledTitle = 'No puedes certificar una fecha que no ha transcurrido';
        }
      }
      break;
    case 'jueves':
      if (props.semana.primerDia.clone().add(3, 'day').month() === props.mes.key) {
        if (!props.resource.activo && props.semana.primerDia.clone().add(3, 'day').isAfter(props.resource.fechaDesvinculacion)) {
          disabledTitle = 'No puedes certificar horas de un recurso despues de su fecha de desvinculacion';
        } else if (!props.semana.primerDia.clone().add(3, 'day').isAfter(moment())) {
          enable = true;
        } else {
          disabledTitle = 'No puedes certificar una fecha que no ha transcurrido';
        }
      }
      break;
    case 'viernes':
      if (props.semana.primerDia.clone().add(4, 'day').month() === props.mes.key) {
        if (!props.resource.activo && props.semana.primerDia.clone().add(4, 'day').isAfter(props.resource.fechaDesvinculacion)) {
          disabledTitle = 'No puedes certificar horas de un recurso despues de su fecha de desvinculacion';
        } else if (!props.semana.primerDia.clone().add(4, 'day').isAfter(moment())) {
          enable = true;
        } else {
          disabledTitle = 'No puedes certificar una fecha que no ha transcurrido';
        }
      }
      break;
    case 'montoTotal':
      break;
  }

  if (props.enable !== undefined) {
    enable = props.enable;
  }

  if (props.noCertificable) {
    enable = false;
    disabledTitle = 'No existe planificación para esta persona en esta fecha';
  }


  const parseValue = (value) => {
    const values = value.replace(/\D/g, '');
    if (values !== '' && values.length >= 4) {
      const horas = values[0] + values[1];
      const min = values[2] + values[3];
      return Number(horas) + Number(min) / 60;
    } else {
      return null;
    }
  };

  const dispatchChange = (val) => {
    setTimeout(() => {
      props.onChange(val);
    }, 70);
  };

  const onChange = (e, newValue) => {
    const parsedValue = parseValue(newValue);
    if (lastValue === null && newValue === '') {
      setValue(e.target.value);
      setTimeout(() => {
        const textField = ref.current._textField;
        textField.current.setSelectionRange(1, 1);
      }, 0);
      return;
    }
  
    if (parsedValue !== null) {
      dispatchChange(parsedValue);
      if (props.onValueChange) {
        props.onValueChange(newValue !== DEFAULT_MASK);
      }
    } else if (lastValue !== null) {
      dispatchChange(parsedValue);
      if (props.onValueChange) {
        props.onValueChange(newValue !== DEFAULT_MASK);
      }
    }
  
    setLastValue(parsedValue);
    setValue(newValue);
  };  

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 9) {
      if (e.shiftKey) {
        props.focusPrevious();
      } else {
        props.focusNext();
      }
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.keyCode === 13) {
      if (value === DEFAULT_MASK) {
        props.focusNext();
        onChange(null, getMaskedNumber(props.suggestedValue));
      }
    }
  };

  const onFocus = (): void => {
    setIsFocused(true);
  };

  return (
    <MaskedTextField
      borderless
      mask={value === DEFAULT_MASK && isFocused ? null : `99:99`}
      maskChar="_"
      placeholder={getMaskedNumber(props.suggestedValue)}
      value={value}
      componentRef={ref}
      onFocus={onFocus}
      onBlur={() => setIsFocused(false)}
      onChange={onChange}
      onKeyDown={onKeyDown}
      spellCheck={'false'}
      disabled={!enable}
      title={!enable === true ? disabledTitle : ''}
      styles={{
        fieldGroup: { height: 'auto' },
        field: { paddingLeft: 0, selectors: { '&:focus': { backgroundColor: '#fff5f5' } } },
        root: {},
      }}
      style={{ fontSize: '12px', fontWeight: 'bold' }}
    />
  );
};
