import React from 'react';
import { AnimatedCurrencyText } from '../AnimatedText/AnimatedCurrencyText';

interface AnimatedCurrencyCellProps {
    value: any;
    bold?: boolean;
    moneda: string;
}

export default function AnimatedCurrencyCell({value, bold = false ,moneda} : AnimatedCurrencyCellProps) {
    let style: any = {};
    if(bold) style.fontWeight = 'bold';

    return (
        <div className={'cell-devengamiento-bare'} style={style}>
            <AnimatedCurrencyText value={value} moneda={moneda} />
        </div>
    );
}