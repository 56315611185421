import React, { useState } from 'react';
import { DetailsListLayoutMode, IDetailsGroupDividerProps, IDetailsHeaderProps, IDetailsRowProps, ScrollablePane, SelectionMode, ShimmeredDetailsList, Sticky } from '@fluentui/react';
import { Depths } from '@uifabric/fluent-theme';
import { compare } from '../../utils/utils';
import TableError from '../TableError/TableError'
import ErrorStatus from '../../interfaces/ErrorStatus';
// import CsvButton from '../CsvButton/CsvButton'
import {returnData,columnFilter} from '../../utils/utils'
import CertificacionFilterButtons from '../CertificacionFilterButtons/CertificacionFilterButtons'

interface BaseTableProps {
    projectName?:string;
    showButtons? :boolean;
    items: Array<Array<any>>;
    columns: any;
    groups?: any;
    defaultColumnSortedKey?: string;
    rowHeigth?: string | number;
    rowFontSize?: string | number;
    error?:ErrorStatus;
    onRenderItemColumn: (item?: any, index?: number, column?: any) => React.ReactNode;
    onRenderGroupHeader?: (props: IDetailsGroupDividerProps | any, def: any) => any;
    moneda?:string|null;
    filtro?: string
}

export default function BaseTable(props: BaseTableProps) {
    const ProjectNameIncludesDots = (ProjectName:string) => ProjectName.replace(/\./g, ''); 

    const [isSortedDesending, setIsSortedDesending] = useState(true);
    const [tableToShow, setTableToShow] = useState("full");
    const [columnSorted, setColumnSorted] = useState(props.defaultColumnSortedKey);

    const onRenderRow = (rowProps: IDetailsRowProps, defaultRender: any) => {
        rowProps.styles = {
            cell: {
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                minHeight: 0
            },
            fields: { minHeight: 'unset' },
            root: {
                minHeight: 'unset',
                height: props?.rowHeigth,
                fontSize: props?.rowFontSize,
                fontWeight: rowProps.item?.isBold ? "bold" : 400,
                selectors: {
                    '&:hover': {
                        backgroundColor: 'white',
                    },
                },
            },
        };
        return defaultRender(rowProps);
    };

    const onRenderHeader = (detailsHeaderProps: IDetailsHeaderProps, defaultRender: any) => {
        detailsHeaderProps.styles = {
            root: {
                height: '40px',
                paddingTop: '0px',
                paddingBottom: '2px',
            },
        };
        return <Sticky>{defaultRender(detailsHeaderProps)}</Sticky>;
    }
    
    const buildItems = () => {
        let itemList = [];
        props.items.forEach(group => {
            if (Array.isArray(group)) {
                group = _copyAndSort(group, columnSorted, isSortedDesending);
                itemList = itemList.concat(group);
            }
        });
    
        itemList = itemList.filter(item => 
            props.filtro === "Todos" || 
            (props.filtro === "Snoopies" ? item?.ptm === false : item?.ptm === true)
        );
        
        return itemList;
    };

    const onColumnClick = (event: React.MouseEvent<HTMLElement>, column: any): void => {
        let _isSortedDescending = column.isSortedDescending;
        if (column.isSorted) {
            _isSortedDescending = !_isSortedDescending;
        }
        setIsSortedDesending(_isSortedDescending);
        setColumnSorted(column.key);
    }

    const buildColumns = () => {
        return props.columns.map(column => {
            const columnStyles = {
                cellTitle: {textAlign: column.textAlign ,height: '100%' },
                sortIcon: { color: 'white' },
                cellName: {
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                }
            };
            return {
                ...column,
                onColumnClick,
                styles: columnStyles,
                isSorted: column.key === columnSorted,
                isSortedDescending: column.key === columnSorted ? isSortedDesending : column.isSortedDesending
            }
        });
    }

    return (
        <>
        {/* {
            props.showButtons ?
                <>
                    <CsvButton
                    table={returnData(props.items.flat(),props.columns.map(column => column.name),tableToShow,props.moneda)}
                    filename={ProjectNameIncludesDots(props.projectName)} />
                    <CertificacionFilterButtons tableToShow={tableToShow} setTableToShow={setTableToShow} />
                </>
            :
            null
        } */}
        <ScrollablePane
            style={{
                position: 'relative',
                flex: 1,
                boxShadow: Depths.depth8,
                backgroundColor: 'white',
            }}>
            <ShimmeredDetailsList
                items={buildItems()}
                columns={columnFilter(buildColumns(),tableToShow)}
                groups={props?.groups}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                onRenderItemColumn={props.onRenderItemColumn}
                onRenderRow={onRenderRow}
                onRenderDetailsHeader={onRenderHeader}
                groupProps={{
                    onRenderHeader: props?.onRenderGroupHeader,
                }}
                />

                {
                props.error?.statusCode !== null && props.error?.statusCode !== undefined  
                    ?
                    <TableError />
                    :
                    null
                }
        </ScrollablePane>
        </>
    )
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    if (!Array.isArray(items)) return [];

    const key = columnKey as keyof T;

    return items.slice(0).sort((a: T, b: T) => {
        const valueA = a?.[key] ?? null;
        const valueB = b?.[key] ?? null;

        if (valueA == null && valueB == null) return 0;
        if (valueA == null) return isSortedDescending ? 1 : -1;
        if (valueB == null) return isSortedDescending ? -1 : 1;

        return compare(typeof valueA, valueA, valueB, isSortedDescending) ? 1 : -1;
    });
}