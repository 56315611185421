import { Link } from '@fluentui/react';
import React, { MouseEventHandler } from 'react';


interface PmCellProps {
    value: any;
}

export default function PmCell(props: PmCellProps){
    return (
        <div className='cell-normal-container'>
            <span>{props.value}</span>
        </div>
    );
}