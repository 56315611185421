import { useRef, MutableRefObject } from 'react';

const useInputsRef = (): [MutableRefObject<any>, (ref: any) => void, () => void, () => void] => {
  const inputsRef = useRef([]);

  const addRef = (ref) => {
    inputsRef.current = Array.from(inputsRef.current.filter((r) => r.current !== null));
    if (inputsRef.current.length === 0) ref.current.focus();
    inputsRef.current.push(ref);
  };

  const focusNext = (index = null) => {
    if (index === null) {
      index = inputsRef.current.findIndex((r) => r.current._isFocused);
    }

    if (index + 1 >= inputsRef.current.length) {
      return focusNext(-1);
    }

    const input = inputsRef.current[index + 1];

    if (!input.current.props.disabled) {
      input.current.focus();
      if (input.current.value !== null && input.current.value !== undefined) {
        input.current.setSelectionRange(input.current.value.length, input.current.value.length);
      }
    } else {
      focusNext(index + 1);
    }
  };

  const focusPrevious = (index = null) => {
    if (index === null) {
      index = inputsRef.current.findIndex((r) => r.current._isFocused);
    }

    if (index === 0) {
      return focusPrevious(inputsRef.current.length);
    }

    const input = inputsRef.current[index - 1];

    if (!input.current.props.disabled) {
      input.current.focus();
    } else {
      focusPrevious(index - 1);
    }
  };

  return [inputsRef, addRef, focusNext, focusPrevious];
};
export default useInputsRef;
