import React from 'react';

//Styles
import './LoaderPDF.css'

//Assets
import manosYPapel from '../../assets/papel-y-manos.png'

const LoaderPDF = () => {
    return (
        <section className='container-loaderpdf'>
            <div className='loaderpdf'>
                <div className='ojos-loader-pdf'>
                    <div className='ojo-pdf ojo-izq'>
                        <div className='interior-ojo'></div>
                    </div>

                    <div className='ojo-pdf ojo-der'>
                        <div className='interior-ojo'></div>
                    </div>
                </div>
                <img src={manosYPapel} alt='Loader PDF Snoop Consulting' className='img-loader-pdf'/>
            </div>
            <h2 className='data-loader-pdf'>Generando Reporte...</h2>
        </section>
    );
};

export default LoaderPDF;