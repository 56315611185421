import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, CircularProgress, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { useDispatch } from 'react-redux';
import { resetIntegracionStatus } from '../../redux/actions/certificados';
import './IntegracionStatus.css'

interface Props {
  open: boolean;
  setOpen: Function;
  success: boolean;
  loader: boolean;
}

export default function IntegracionStatus(props: Props) {
  const [status, setStatus] = React.useState(0);
  const dispatch = useDispatch();

  const handleClose = () => {
    props.setOpen(false);
    setTimeout(() => {
      setStatus(0);
      dispatch(resetIntegracionStatus());
    }, 500);
  };

  React.useEffect(() => {
    if (props.success === true) {
      setStatus(1);
    } else if (props.success === false) {
      setStatus(2);
    }
  }, [props.success]);

  React.useEffect(() => {
    if (props.loader === true) {
      props.setOpen(true);
    }
  }, [props.loader]);

  React.useEffect(() => {
    if (props.open && status === 1) {
      const timer = setTimeout(() => {
        handleClose();
      }, 5000); // 5 segundos para el cierre automático

      return () => clearTimeout(timer);
    }
  }, [props.open, status]);

  const getBorderColor = () => {
    switch (status) {
      case 1:
        return 'green';
      case 2:
        return 'red';
      default:
        return null;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          backdropFilter: 'blur(1.5px)'
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{
          borderLeft: `4px solid ${getBorderColor()}`,  
        }}>
          {"Estado de Integración"}
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            borderLeft: `4px solid ${getBorderColor()}`,
            width: '25rem'
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              display: 'flex',
            }}
          >
            {(() => {
              switch (status) {
                case 0:
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <div style={{
                        flex: '2'
                      }}>
                        <CircularProgress/>
                      </div>
                      <div style={{
                        flex: '8',
                        margin: '0 1rem'
                      }}>
                        <h5
                          style={{
                            fontSize: '15px',
                            textAlign: 'center',
                            color: '#222'
                          }}
                        >
                          Enviando integración...
                        </h5>
                      </div>
                    </Box>
                  );
                case 1:
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CheckIcon
                      sx={{
                        fontSize: '25px',
                        color: 'green'
                      }}
                      fontSize="inherit" />
                      <span style={{ marginLeft: '0.5rem', fontSize: '16px'}}>
                        La integración se completó con éxito.
                      </span>
                    </Box>
                  );
                case 2:
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ErrorIcon fontSize="inherit" sx={{
                        fontSize: '25px',
                        color: '#be0411'
                      }}/>
                      <span style={{ marginLeft: '0.5rem', fontSize: '16px'}}>
                        Error procesando la integración.
                      </span>
                    </Box>
                  );
                default:
                  return null;
              }
            })()}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            borderLeft: `4px solid ${getBorderColor()}`,  
          }}>
          {!props.loader && (
            <Button
              sx={{ color: '#333' }}
              className= {status == 1 ? "button-border-animation" : null}
              onClick={handleClose}
              autoFocus
            >
              Cerrar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
