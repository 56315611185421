import { Box, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ITablaIntegracion from '../../../interfaces/ITablaIntegracion';
import './TablaIntegracion.css';

const StyledDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-footerContainer': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader':{
    backgroundColor: '#be0411',
    color: '#ddd'
  },
  '.css-i4bv87-MuiSvgIcon-root': {
    color: '#111',
  },
  '& .MuiDataGrid-columnHeader .MuiSvgIcon-root:first-of-type': {
    color: '#ddd', // El color que deseas para el primer ícono
  },
  '& .MuiDataGrid-columnHeader--emptyGroup': {
    display: 'none'
  },
  '& .MuiDataGrid-row.blinking-row': {
    animation: 'blink 2s infinite',
    fontWeight: '600'
  }
});

interface IDatosActuales{
  monto: number,
  fecha: string,
  horas: number,
  mes: number,
  anio: number
}

interface Props{
  tabla?: Array<ITablaIntegracion>
  actuales: IDatosActuales
}

const TablaIntegracion = (props: Props) => {
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'estado',
      headerName: 'Estado',
      flex: 1,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case 1:
            color = 'green';
            break;
          case 2:
            color = 'red';
            break;
          case 0:
          default:
            color = 'gray';
            break;
        }
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <RadioButtonCheckedIcon style={{ color }} />
          </Box>
        );
      },
    },
    {
      field: 'nCertificacion',
      headerName: 'N° Certificación',
      flex: 2,
      editable: false,
      renderCell: (params) => (
        <a href={`#/${params.value}`} style={{ textDecoration: 'none', color: '#1a0dab' }}>
          {params.value}
        </a>
      ),
    },
    {
      field: 'fecha',
      headerName: 'Fecha',
      flex: 2,
      editable: false,
      renderCell: (params) => (
        <h4>{params.value ? params.value : '-'}</h4>
      ),
    },
    {
      field: 'monto',
      headerName: 'Monto certificado',
      flex: 2,
      editable: false,
      renderCell: (params) => (
        <h4>{`$${params.value}`}</h4>
      ),
    },
    {
      field: 'horas',
      headerName: 'Horas certificadas',
      flex: 2,
      editable: false,
      renderCell: (params) => (
        <h4>{`${params.value}hs`}</h4>
      ),
    },
    {
      field: 'fechaIntegracion',
      headerName: 'Fecha de integración',
      flex: 2,
      editable: false,
      renderCell: (params) => (
        <h4>{params.value}</h4>
      ),
    },
    {
      field: 'usuario',
      headerName: 'Usuario',
      flex: 2,
      editable: false,
      renderCell: (params) => (
        <h4>{params.value}</h4>
      ),
    },
    {
      field: 'id',
      headerName: 'Identificador',
      flex: 3,
      editable: false,
      renderCell: (params) => (
        <h4>{params.value}</h4>
      ),
    },
  ];

  const [rows, setRows] = useState<any[]>([]);

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (props.actuales) {
      const actualRow = {
        id: 'actual',
        estado: 0,
        nCertificacion: null,
        fecha: props.actuales.fecha?.split('T')[0],
        monto: props.actuales?.monto.toFixed(2),
        horas: props.actuales?.horas.toFixed(2),
        fechaIntegracion: today,
        usuario: 'Usuario actual',
      };

      setRows([actualRow, ...(Array.isArray(props?.tabla) ? props?.tabla : [])]);
    }
  }, [props.actuales, props.tabla]);

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden', overflowY: 'hidden' }}>
      <h2>Historial de Integraciones</h2>
      <StyledDataGrid
        rows={rows.map((row, index) => ({
          ...row,
          className: index === 0 ? 'blinking-row' : null,
        }))}
        columns={columns}
        hideFooter={false}
        disableRowSelectionOnClick
        getRowClassName={(params) => params.row.className}
        autoHeight = {false}
        rowHeight={32}
      />
    </Box>
  );
}

export default TablaIntegracion
