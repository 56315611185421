import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { saveCargas } from '../../redux/actions/certificados';
import './AnimatedTimeText.css'

interface Props {
  value: number;
  style?: any;
  title?: string;
  concepto?: string;
  edit?: boolean;
  idProject?: string;
  fecha?: string;
  legajo?: number;
}

interface Cargas{
  aprobacion: boolean;
  fechaCarga: string;
  horas: number;
  id: number;
  inicio: string;
  legajo: number;
}

export const AnimatedTimeText = (props: Props): JSX.Element => {
  const [interVal, set] = useSpring(() => ({ value: props.value }));
  const [icon, setIcon] = useState(1)
  const dispatch = useDispatch()
  const stateCargas:Array<Cargas> = useSelector((state: RootStateOrAny) => state.generalReducer.proyecto.cargasToggl);
  const cargasActuales = stateCargas.filter(c => 
    c.inicio.slice(0, 10) === props.fecha && c.legajo === props.legajo);
  const aprobaciones = (cargasActuales) ? cargasActuales[cargasActuales.length - 1]?.aprobacion : null;

  useEffect(() => {
    set({ value: props.value });
  }, [props]);

  useEffect(() => {
    if (cargasActuales.length > 0) {
      setIcon(aprobaciones === true ? 2 : aprobaciones === false ? 3 : 1);
    }
  }, [cargasActuales.length, aprobaciones]);

  const handlerTypeIcon = () =>{
    if(icon === 1){
      setIcon(2)
      dispatch(saveCargas({horas: props.value, aprobado: true, fecha: props.fecha, legajo: props.legajo, idProyecto: props.idProject}))
    }
    
    if(icon === 2){
      setIcon(3)
      dispatch(saveCargas({horas: props.value, aprobado: false, fecha: props.fecha, legajo: props.legajo, idProyecto: props.idProject}))
    }
    
    if(icon === 3){
      setIcon(1)
      dispatch(saveCargas({horas: props.value, aprobado: null, fecha: props.fecha, legajo: props.legajo, idProyecto: props.idProject}))
    }
  }

  const handlerColors = (numberType: number) =>{
    if(props.concepto === 'Trackeado' && !props.title){
      if(numberType === 2 && props.value !== 0){
        return {color: 'green'}
      }
  
      if(numberType === 3 && props.value !== 0){
        return {color: '#be0411'}
      }
    }

    return props.style
  }

  const handlerColorsDisabled = () =>{
    if(props.concepto === 'Trackeado' && !props.title){
      if( props.value === 0){
        return {color: '#CCCCCC'}
      }
    }

    return props.style
  }

  const handlerIcons = (numberType: number) =>{
    if(numberType === 1){
      return <CheckBoxOutlineBlankIcon sx={{...handlerColors(icon), ...handlerColorsDisabled(), cursor: 'pointer', marginLeft: '3px'}}/>
    }

    if(numberType === 2){
      return <CheckBoxIcon sx={{...handlerColors(icon), cursor: 'pointer', marginLeft: '3px'}}/>
    }

    if(numberType === 3){
      return <DisabledByDefaultIcon sx={{...handlerColors(icon), cursor: 'pointer', marginLeft: '3px'}} />
    }
  }

  return (
    <>
      <animated.span title={props.title} style={handlerColors(icon)}>
        {interVal.value.interpolate((x: number) => x.toTime())}
      </animated.span>
      {(props.concepto === 'Trackeado' && !props.title) && 
        <div
          className={`container-icon ${props.edit && 'container-icon-active'} 
          ${props.value === 0 ? 'container-icon-disabled' : ''}`}
          onClick={props.edit ? handlerTypeIcon : undefined} // Solo permitir clics si `edit` es `true`
        >
          {handlerIcons(icon)}
        </div>}
    </>
  );
};
