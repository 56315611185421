import * as React from 'react';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { isBefore } from 'date-fns'
import './RangePicker.css'

interface Props{
    valueDesde?: string;
    valueHasta?: string;
    onChangeFecha?: (any) => void;
    max?: string;
    hoy?: string;
}

const RangePicker:React.FC<Props> = (props: Props) => {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: 'Desde', end: 'Hasta' }}
        >
            <DateRangePicker
                maxDate={isBefore(new Date(props.hoy), new Date(props.max)) ? dayjs(props.hoy) : dayjs(props.max)}
                value={[dayjs(props.valueDesde), dayjs(props.valueHasta)]}
                onChange={(newValue) => {
                    props.onChangeFecha([newValue[0]['$d'], newValue[1]['$d']])
                }}
                renderInput={(startProps, endProps) => {
                    startProps.inputProps.value = dayjs(startProps.inputProps.value).format('YYYY/MM/DD')
                    endProps.inputProps.value = dayjs(endProps.inputProps.value).format('YYYY/MM/DD')

                    return(
                    <React.Fragment>
                        <TextField {...startProps}/>
                        <Box sx={{ mx: 1 }}>{'>'}</Box>
                        <TextField {...endProps} />
                    </React.Fragment>
                )}}
            />
        </LocalizationProvider>
    );
}

export default RangePicker