import moment, { Moment } from 'moment';
import IDiaPlanificado from '../../../interfaces/IDiaPlanificado';
import { ITableItem } from './TablaCertificacion';
import ICargaToggl from './../../../interfaces/ICargaToggl';
import IDiaCertificado from './../../../interfaces/IDiaCertificado';
import shortid from 'shortid';
import ISemana from './../../../interfaces/ISemana';
import ISemanaCertificada from './../../../interfaces/ISemanaCertificada';
import IMes from './../../../interfaces/IMes';
import IProyecto from './../../../interfaces/IProyecto';
import IPlanificacion from '../../../interfaces/IPlanificacion';

  // Función para obtener horas planificadas para un día específico
const getPlanificadoDia = (fecha: Moment, legajo: number, planificacionFiltrado: IPlanificacion[]) => {
  const planifDia = planificacionFiltrado.find(p => moment(p.fecha).isSame(fecha, 'day') && p.legajo === legajo);
  return planifDia ? planifDia.horasPromedio : 0;
};

function getTogglDia(fecha: Moment, legajo: number, cargasToggl: ICargaToggl[]) {
  if (!cargasToggl || cargasToggl.length === 0) return 0;

  const cargas = cargasToggl.filter(c => c.legajo === legajo && moment(c.inicio).isSame(fecha, 'day'));

  return cargas.length > 0 ? cargas.reduce((acc, carga) => acc + carga.horas, 0) : 0;
}

function getCertificacionDia(legajo: number, fecha: Moment, diasCertificados: IDiaCertificado[], certificadosHorasCreados: IDiaCertificado[]) {
  let diaCertificado = certificadosHorasCreados.find(
    (dia) => dia.legajo === legajo && moment(dia.fecha).hours(0).diff(fecha.hours(0), 'day', true) === 0
  );

  if (diaCertificado !== undefined) {
    if (diaCertificado.valido === true) {
      return diaCertificado;
    } else {
      diaCertificado = undefined;
    }
  } else {
    diaCertificado = diasCertificados.find(
      (dia) => dia.legajo === legajo && moment(dia.fecha, 'YYYY-MM-DD').hours(0).diff(fecha.hours(0), 'day', true) === 0
    );
  }

  if (diaCertificado === undefined)
    return <IDiaCertificado>{
      horas: null,
      fecha: fecha.format('YYYY-MM-DD'),
      fechaCertificacion: null,
      legajo: legajo,
      idCertificacion: null,
    };

  return diaCertificado;
}

function getCertificacionSemana(
  legajo: number,
  mes: IMes,
  semana: ISemana,
  certificadosSemanas: Array<ISemanaCertificada>,
  certificadosSemanasCreados: Array<ISemanaCertificada>
) {
  let semanaCertificada = certificadosSemanasCreados.find(
    (sem) =>
      sem.mes === mes.key &&
      sem.valido === true &&
      sem.legajo === legajo &&
      moment(sem.fecha).hours(0).diff(semana.primerDia.hours(0), 'day', true) === 0
  );
  if (semanaCertificada === undefined) {
    semanaCertificada = certificadosSemanas.find(
      (sem) =>
        sem.mes === mes.key && sem.legajo === legajo && moment(sem.fecha, 'YYYY-MM-DD').hours(0).diff(semana.primerDia, 'day', true) === 0
    );
  }

  if (semanaCertificada === undefined)
    return <ISemanaCertificada>{
      devengado: null,
      fecha: semana.primerDia.format('YYYY-MM-DD'),
      fechaCertificacion: null,
      legajo: legajo,
      mes: mes.key,
      idCertificacion: null,
    };
  return semanaCertificada;
}

function getTotalPlanificado(desde: Moment | string, hasta: Moment | string, mes: IMes, legajo: number, planificacionFiltrado: IPlanificacion[]) {
  let total = 0;
  desde = moment(desde);
  hasta = moment(hasta).add(1, 'day'); // Asegura incluir el último día

  for (; desde.isBefore(hasta, 'day'); desde.add(1, 'day')) {
    if (desde.month() === mes.key) {
      total += getPlanificadoDia(desde, legajo, planificacionFiltrado);
    }
  }

  return total;
}

function getTotalTrackeado(desde: Moment | string, hasta: Moment | string, mes: IMes, legajo: number, cargasToggl: ICargaToggl[]) {
  let total = 0;
  desde = moment(desde);
  hasta = moment(hasta).add(1, 'day'); // Asegura incluir el último día

  for (; desde.isBefore(hasta, 'day'); desde.add(1, 'day')) {
    if (desde.month() === mes.key) {
      total += getTogglDia(desde, legajo, cargasToggl);
    }
  }

  return total;
}

function getTotalCertificado(legajo: number, desde: Moment | string, hasta: Moment | string, mes: IMes, diasCertificados: IDiaCertificado[], certificados: IDiaCertificado[]) {
  let total = 0;
  desde = moment(desde);
  hasta = moment(hasta).add(1, 'day');
  for (; !desde.isSame(hasta, 'days'); desde.add(1, 'day')) {
    if (desde.month() === mes.key) {
      total = total + getCertificacionDia(legajo, desde, diasCertificados, certificados).horas;
    }
  }

  return total;
}

function createListPlanificado(planificacion: IPlanificacion[], proyecto: IProyecto, semana: ISemana, mes: IMes) {
  if (!semana) return [];
  if (!planificacion?.length) return [];
  const list: Array<ITableItem> = [];

  const planificacionFiltrado = planificacion.filter(planificacion => {
    const fechaPlanificacion = moment(planificacion.fecha);
    const fechaInicioSemana = semana.primerDia;
    const fechaFinSemana = semana.primerDia.clone().add(4, 'days');

    return fechaPlanificacion.isBetween(fechaInicioSemana, fechaFinSemana, 'day', '[]');
  });

  const recursos = proyecto.recursos;

  planificacionFiltrado.forEach((planif) => {


    let horaPromedio = planif.horasPromedio;
 
    const dateObj = new Date(planif.fecha);

    const OtroMes = dateObj.getMonth() + 1;

    if(mes.key !== OtroMes ){
      horaPromedio = 0;
    }


    const recurso = recursos.find(recurso => recurso.legajo === planif.legajo);
    if (!recurso) return;

    if (!list.some((l) => l.colaborador === recurso.nombre)) {
      const fila = {
        colaborador: recurso.nombre,
        ptm: recurso.ptm,
        concepto: 'Planificado',
        lunes: getPlanificadoDia(semana.primerDia, planif.legajo, planificacionFiltrado),
        martes: getPlanificadoDia(semana.primerDia.clone().add(1, 'day'), planif.legajo, planificacionFiltrado),
        miercoles: getPlanificadoDia(semana.primerDia.clone().add(2, 'day'), planif.legajo, planificacionFiltrado),
        jueves: getPlanificadoDia(semana.primerDia.clone().add(3, 'day'), planif.legajo, planificacionFiltrado),
        viernes: getPlanificadoDia(semana.primerDia.clone().add(4, 'day'), planif.legajo, planificacionFiltrado),
        totalHoras: 0,
        horaAvg: planif.valorHora,
        montoTotal: horaPromedio * planif.valorHora,
        id: shortid.generate()
      };
      list.push(fila);
    } else {
      const colIndex = list.findIndex((l) => l.colaborador === recurso.nombre);
      const col = list[colIndex];

      if (col) {
        col.lunes = getPlanificadoDia(semana.primerDia, planif.legajo, planificacionFiltrado);
        col.martes = getPlanificadoDia(semana.primerDia.clone().add(1, 'day'), planif.legajo, planificacionFiltrado);
        col.miercoles = getPlanificadoDia(semana.primerDia.clone().add(2, 'day'), planif.legajo, planificacionFiltrado);
        col.jueves = getPlanificadoDia(semana.primerDia.clone().add(3, 'day'), planif.legajo, planificacionFiltrado);
        col.viernes = getPlanificadoDia(semana.primerDia.clone().add(4, 'day'), planif.legajo, planificacionFiltrado);

        col.totalHoras = getTotalPlanificado(semana.primerDia, semana.primerDia.clone().add(4, 'days'), mes, planif.legajo, planificacionFiltrado);
        col.horaAvg = planif.valorHora === 0 ? col.horaAvg : planif.valorHora;
        col.montoTotal = col.totalHoras * col.horaAvg;
      }
    }
  });

  return list;
}

function createListToggl(planificacion: IPlanificacion[], proyecto: IProyecto, semana: ISemana, mes: IMes) {
  if (!semana || !planificacion?.length) return [];
  const list: Array<ITableItem> = [];

  const planificacionFiltrado = planificacion.filter(planif => 
    moment(planif.fecha).isBetween(semana.primerDia, semana.primerDia.clone().add(4, 'days'), 'day', '[]')
  );

  const recursos = proyecto.recursos;

  planificacionFiltrado.forEach(planif => {
    const recurso = recursos.find(r => r.legajo === planif.legajo);
    const cargasToggl = proyecto?.cargasToggl.filter(c => c.legajo === planif.legajo) || [];
    
    if (!recurso) return;

    if (!list.some(l => l.colaborador === recurso.nombre)) {
      const fila = {
        colaborador: recurso.nombre,
        ptm: recurso.ptm,
        concepto: 'Trackeado',
        lunes: getTogglDia(semana.primerDia, planif.legajo, cargasToggl),
        martes: getTogglDia(semana.primerDia.clone().add(1, 'day'), planif.legajo, cargasToggl),
        miercoles: getTogglDia(semana.primerDia.clone().add(2, 'day'), planif.legajo, cargasToggl),
        jueves: getTogglDia(semana.primerDia.clone().add(3, 'day'), planif.legajo, cargasToggl),
        viernes: getTogglDia(semana.primerDia.clone().add(4, 'day'), planif.legajo, cargasToggl),
        totalHoras: getTotalTrackeado(semana.primerDia, semana.primerDia.clone().add(4, 'days'), mes, planif.legajo, cargasToggl),
        horaAvg: '-',
        montoTotal: 0,
        id: shortid.generate(),
        sumados: cargasToggl.map(c => c.id)
      };

      fila.montoTotal = fila.totalHoras * planif.valorHora;
      list.push(fila);
    } else {
      const colIndex = list.findIndex(l => l.colaborador === recurso.nombre);
      const col = list[colIndex];

      if (col) {
        const cargasNoSumadas = cargasToggl.filter(c => !col.sumados.includes(c.id));

        if (cargasNoSumadas.length > 0) {
          col.lunes += getTogglDia(semana.primerDia, planif.legajo, cargasNoSumadas);
          col.martes += getTogglDia(semana.primerDia.clone().add(1, 'day'), planif.legajo, cargasNoSumadas);
          col.miercoles += getTogglDia(semana.primerDia.clone().add(2, 'day'), planif.legajo, cargasNoSumadas);
          col.jueves += getTogglDia(semana.primerDia.clone().add(3, 'day'), planif.legajo, cargasNoSumadas);
          col.viernes += getTogglDia(semana.primerDia.clone().add(4, 'day'), planif.legajo, cargasNoSumadas);
          col.totalHoras = getTotalTrackeado(semana.primerDia, semana.primerDia.clone().add(4, 'days'), mes, planif.legajo, cargasToggl);
          col.montoTotal = col.totalHoras * planif.valorHora;
          
          cargasNoSumadas.forEach(carga => col.sumados.push(carga.id));
        }
      }
    }
  });

  return list;
}

function createListCertificacion(
  planificacion: Array<IPlanificacion>,
  proyecto: IProyecto,
  semana: ISemana,
  mes: IMes,
  certificadosHorasCreados: Array<IDiaCertificado>,
  certificadosSemanasCreados: Array<ISemanaCertificada>
) {
  if (semana === null) return [];
  if (planificacion === null || planificacion.length === 0) return [];
  const list: Array<ITableItem> = [];
  const planificacionFiltrado = planificacion.filter(planificacion => {
    const fechaPlanificacion = moment(planificacion.fecha);
    const fechaInicioSemana = semana.primerDia;
    const fechaFinSemana = semana.primerDia.clone().add(4, 'days');

    return fechaPlanificacion.isBetween(fechaInicioSemana, fechaFinSemana, 'day', '[]');
  });
  
  const certificadosSemanas = proyecto.certificados.certificadosDevengamiento.filter(cd => cd.mes === mes.key);
  const certificadosHoras = proyecto.certificados.certificadosHoras.filter(ch => moment(ch.fecha).isBetween(moment(semana.primerDia).subtract(1, 'days'), moment(semana.primerDia).add(5, 'd')));
  const recursos = proyecto.recursos;

  planificacionFiltrado.forEach((planif) => {
    const recurso = recursos.find(recurso => recurso.legajo === planif.legajo);    
    if(!recurso) return;

    if (!list.some((l) => l.colaborador === recurso.nombre)) {
      const cer = {
        isBold: true,
        colaborador: recurso.nombre,
        fechaDesvinculacion: recurso.fechaEgreso,
        recursoActivo: recurso.activo,
        ptm: recurso.ptm,
        concepto: 'Certificado',
        lunes: getCertificacionDia(recurso.legajo, semana.primerDia, proyecto.certificados.certificadosHoras, certificadosHorasCreados),
        martes: getCertificacionDia(
          recurso.legajo,
          semana.primerDia.clone().add(1, 'day'),
          certificadosHoras,
          certificadosHorasCreados
        ),
        miercoles: getCertificacionDia(
          recurso.legajo,
          semana.primerDia.clone().add(2, 'day'),
          certificadosHoras,
          certificadosHorasCreados
        ),
        jueves: getCertificacionDia(
          recurso.legajo,
          semana.primerDia.clone().add(3, 'day'),
          certificadosHoras,
          certificadosHorasCreados
        ),
        viernes: getCertificacionDia(
          recurso.legajo,
          semana.primerDia.clone().add(4, 'day'),
          certificadosHoras,
          certificadosHorasCreados
        ),
        totalHoras: 0,
        horaAvg: 0,
        montoTotal: getCertificacionSemana(
          recurso.legajo,
          mes,
          semana,
          certificadosSemanas,
          certificadosSemanasCreados
        ),
        id: shortid.generate(),
        legajo: recurso.legajo,
      };
      cer.totalHoras = getTotalCertificado(
        recurso.legajo,
        semana.primerDia,
        semana.primerDia.clone().add(4, 'day'),
        mes,
        certificadosHoras,
        certificadosHorasCreados
      );
      cer.horaAvg = cer.totalHoras > 0 && cer.montoTotal.devengado !== null ? cer.montoTotal.devengado / cer.totalHoras : 0;
      list.push(cer);
    }
  });

  return list;
}

export function createListItems(
  planificacion: Array<IPlanificacion>,
  proyecto: IProyecto,
  semana: any,
  mes: IMes,
  certificadosHorasCreados: Array<IDiaCertificado>,
  certificadosSemanasCreados: Array<ISemanaCertificada>
): Array<ITableItem> {
  //asig = asig.filter(asignacion => moment(semana?.primerDia).isBetween(asignacion.fechaInicio, asignacion.fechaFin));
  let list = createListPlanificado(planificacion, proyecto, semana, mes).concat(createListToggl(planificacion, proyecto, semana, mes));
  list = list.concat(createListCertificacion(planificacion, proyecto, semana, mes, certificadosHorasCreados, certificadosSemanasCreados));
  list = list.filter((l) => list.some((li) => li.colaborador === l.colaborador && li.concepto === 'Planificado'));
  return list.sort((a, b) => a.colaborador.localeCompare(b.colaborador));
}
