import {configureStore} from "@reduxjs/toolkit"
import { generalReducer } from "./reducers"
import { SET_USER ,SET_USER_LOADING} from "./types/types"

export const store = configureStore({
  reducer:{
    generalReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [SET_USER,SET_USER_LOADING],
        ignoredPaths: ['generalReducer.user','generalReducer.loadinguser'],
      },
    })
})