import { Link } from '@fluentui/react';
import React, { MouseEventHandler } from 'react';


interface SbuProyectoCellProps {
    value: any;
}

export default function SbuProyectoCell(props: SbuProyectoCellProps){
    return (
        <div className='cell-normal-container'>
            <span>{props.value}</span>
        </div>
    );
}