import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { useState } from 'react';
import IMes from './../../../interfaces/IMes';
import IProyecto from '../../../interfaces/IProyecto';

const useMeses = (proyecto: IProyecto, mesInicial: string) => {
  let meses: Array<IMes> = [];
  const [mesSelected, setMes] = useState(null);

  const setMesSelected = (mes) => {
    if (mes) {
      const auxM = moment(mes.value, 'MMMM YYYY');
      const newM = meses.find((m) => m.key === auxM.month() && m.year === auxM.year());
      setMes(newM);
    } else {
      setMes(null);
    }
  };

  if (proyecto && Array.isArray(proyecto.planificacion)) {
    proyecto.planificacion.forEach((planif) => {
      const fecha = moment(planif.fecha);
      const inicio = fecha.clone().startOf('month');
      const fin = fecha.clone().endOf('month');
  
      // Verificamos si ya existe el mes en el arreglo 'meses'
      if (meses.length === 0 || meses.some((m) => m.key === inicio.month() && m.year === inicio.year()) === false) {
        // Si no existe, lo agregamos
        meses.push({
          key: inicio.month(),
          value: inicio.locale('es').format('MMMM YYYY'),
          year: inicio.year(),
          primerDia: inicio.clone().startOf('month'),
        });
      }
    });
  }
  meses = meses.sort((a, b) => a.key - b.key);
  meses = meses.sort((a, b) => a.year - b.year);

  if (mesSelected === null && meses.length > 0) {
    if (mesInicial) {
      setMesSelected(getMesMasCercano(meses, moment(mesInicial, 'MM-YYYY')));
    } else {
      setMesSelected(getMesMasCercano(meses, moment()));
    }
  }

  return [meses, mesSelected, setMesSelected];
};

const getMesMasCercano = (meses: Array<IMes>, fecha: Moment) => {
  let masCercano = meses[0];
  for (const mes of meses) {
    if (Math.abs(mes.primerDia.diff(fecha, 'M')) < Math.abs(fecha.diff(masCercano.primerDia, 'M'))) {
      masCercano = mes;
    }
  }
  return masCercano;
};

export default useMeses;