import React, { useState, useEffect } from 'react';
import { History } from 'history';
import { Text } from '@fluentui/react/lib/Text';
import { Link } from '@fluentui/react/lib/Link';
import { Icon } from '@fluentui/react/lib/Icon';
import Header from '../Header/Header';
import User from '../../interfaces/User';
import MyModal from './MyModal/MyModal';
import InfoProyecto from './InfoProyecto/InfoProyecto';
import TablaCertificacion from './TablaCertificacion/TablaCertificacion';
import TablaBare from './TablaBare/TablaBare';
import IProyecto from '../../interfaces/IProyecto';
import IDiaCertificado from './../../interfaces/IDiaCertificado';
import ISemanaCertificada from './../../interfaces/ISemanaCertificada';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { PROJECT_ONLINE_URL } from './../../constants/index';
import useMeses from './Hooks/useMeses';
import useSemana from './Hooks/useSemana';
import './proyecto.css';
import IMes from './../../interfaces/IMes';
import ISemana from '../../interfaces/ISemana';
import EditorTab from './EditorTab/EditorTab';
import SelectorMes from './Selectors/SelectorMes';
import SelectorSemana from './Selectors/SelectorSemana';
// import { IconButton } from '@fluentui/react';
import ErrorStatus from '../../interfaces/ErrorStatus';
import GenerarReporteButton from '../GenerarReporteButton/GenerarReporteButton';
import SelectorFiltro from './Selectors/SelectorFiltro';
import { format } from 'date-fns'
import WidgetAccesos from '../WidgetAccesos/WidgetAccesos';
import { clearCargas, clearComentario } from '../../redux/actions/certificados/index'
import { useDispatch, useSelector } from 'react-redux';
import LoaderPDF from '../LoaderPDF/LoaderPDF';
import ModalComentario from './ModalComentario/ModalComentario';
import { findIndexByValue } from '../../utils/utils'
import IPlanificacion from '../../interfaces/IPlanificacion';
import ERPintegracionButton from './IntegracionButton/IntegracionButton';
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const MyToolTip = () => (
  <TooltipHost
    styles={{
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '1vw',
      },
    }}
    content="Si no encontras un recurso, verifica que este cargado en project online.">
    <Icon style={{ cursor: 'pointer' }} iconName={'AlertSolid'} />
  </TooltipHost>
);

interface Props {
  user: User;
  proyecto: IProyecto;
  history: History;
  proyectos: Array<IProyecto>;
  onProyectoChange: (id: string) => void;
  idProject: string;
  addCertificado: (c: IDiaCertificado) => void;
  saveCertificados: () => void;
  certificadosHoras: Array<IDiaCertificado>;
  certificadosDevengamiento: Array<ISemanaCertificada>;
  clearCertificados: () => void;
  addCertificadoDevengamiento: (c: ISemanaCertificada) => void;
  semanaInicial: string;
  mesInicial: string;
  updateQuery: (mes: IMes, semana: ISemana) => void;
  error: ErrorStatus;
  // a agregar en llamado de componente
  // items: Array<Array<any>>;
  // columns: any;
  // moneda?:string|null;
  // projectName?:string;
}

const Proyecto: React.FC<Props> = (props: Props) => {
  const [meses, mesSelected, setMesSelected] = useMeses(props.proyecto, props.mesInicial);
  const [nextMonthWeek, setNextMonthWeek] = useState(false);
  const [semanas, semanaSelected, setSemanaSelected] = useSemana(mesSelected, props.proyecto, props.semanaInicial, nextMonthWeek);
  const [bypass, setBypass] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [toDoModal, setToDoModal] = useState(null);
  const [hayCambios, setHayCambios] = useState(false);
  const [edit, setEdit] = useState(false);
  const [disableLastWeek, setDisableLastWeek] = useState(false);
  const [disableNextWeek, setDisableNextWeek] = useState(false);
  const [filtro, setFiltro] = useState("Todos")
  const [inputDisabled, setInputDisabled] = useState("")
  const [fechaInicioProyecto, setFechaInicioProyecto]: any = useState("")
  const fechasFin = props?.proyecto?.planificacion?.map(planificacion => new Date(planificacion.fecha));
  const fechaMax = new Date(Math.max.apply(null, fechasFin));
  const distpach = useDispatch()
  const loaderPdf = useSelector<any>(state => state.generalReducer.pdfLoading)
  const [integrationModal, setIntegrationModal] = useState(false);
  const [tableToShow, setTableToShow] = useState("full");


  useEffect(() => {
    if (mesSelected && semanaSelected) {
      props.updateQuery(mesSelected, semanaSelected);
      if ((meses[meses.length - 1].value === mesSelected.value) && (semanas[semanas.length - 1].value === semanaSelected.value)) {
        setDisableNextWeek(true)
      }

      if ((meses[0].value === mesSelected.value) && (semanas[0].value === semanaSelected.value)) {
        setDisableLastWeek(true)
      }
    }
    
    const proyecto = props.proyecto;

    if (proyecto?.planificacion) {
      const planificacionHoras = proyecto.planificacion;

      const asignacionesPtm = planificacionHoras.filter(p => p.legajo >= 50000 && p.legajo <= 59999);
      const asignacionesSnoopies = planificacionHoras.filter(p => p.legajo <= 49999);
      // const inicioProyecto = planificacionHoras.reduce((minDate, f) => 
      //   new Date(f.fecha) < minDate ? new Date(f.fecha) : minDate, new Date('9999-12-31'));
      
      // setFechaInicioProyecto(inicioProyecto.toISOString());
      const inicioProyecto = planificacionHoras
        .map(f => new Date(f.fecha)) 
        .sort((a, b) => a.getTime() - b.getTime());
      
      setFechaInicioProyecto(inicioProyecto[0] ?? new Date('2002-01-01')); 
    
      if (asignacionesPtm.length === 0) {
        setInputDisabled("ptms");
      }
      if (asignacionesSnoopies.length === 0) {
        setInputDisabled("snoopies");
      }
    }
  }, [mesSelected, semanaSelected]);

  const onMesChange = (mes, nextMonth: boolean) => {
    setDisableLastWeek(false)
    setDisableNextWeek(false)

    const todo = (save: boolean) => {
      setOpenModal(false);
      setBypass(true);
      setNextMonthWeek(nextMonth)
      setSemanaSelected(null);
      setMesSelected({ key: mes.key, value: mes.value });
      setTimeout(() => setBypass(false), 50);
      if (hayCambios) props.clearCertificados();
      setHayCambios(false);
    };
    if (hayCambios) {
      setToDoModal(() => todo);
      setOpenModal(true);
    } else {
      todo(false);
    }
  };

  const onSemanaChange = (semana) => {
    setDisableLastWeek(false)
    setDisableNextWeek(false)

    const todo = (save: boolean) => {
      setOpenModal(false);
      setBypass(true);
      setSemanaSelected(semana);
      setTimeout(() => setBypass(false), 50);
      if (hayCambios) props.clearCertificados();
      setHayCambios(false);
    };
    if (hayCambios) {
      setToDoModal(() => todo);
      setOpenModal(true);
    } else {
      todo(false);
    }
  };

  const filtrarPlanificacion = (planificacionHoras: Array<IPlanificacion>) => {
    return planificacionHoras && bypass === false ? planificacionHoras : [];
  }

  const getProjectUrl = () => {
    return PROJECT_ONLINE_URL + props.idProject;
  };

  const showNextWeek = () => {
    setDisableLastWeek(false)

    const nextWeek = semanas[findIndexByValue(semanas, semanaSelected.value) + 1]

    if (semanas[semanas.length - 1].id === semanaSelected.id) {
      onMesChange(meses[findIndexByValue(meses, mesSelected.value) + 1], true)
    }

    onSemanaChange(nextWeek)
  }

  const showLastWeek = () => {
    setDisableNextWeek(false)

    const lastWeek = semanas[findIndexByValue(semanas, semanaSelected.value) - 1]

    if (semanas[0].id === semanaSelected.id) {
      onMesChange(meses[findIndexByValue(meses, mesSelected.value) - 1], false)
    }

    onSemanaChange(lastWeek)
  }

  const handleInputs = (event) => {
    setFiltro(event.value)
  }

  const ProjectNameIncludesDots = (ProjectName:string) => ProjectName.replace(/\./g, ''); 

  return (
    <div className={'mainContainer'}>
      <MyModal
        isOpen={openModal}
        toDo={toDoModal}
        onSuccess={() => ({})}
        onCancel={() => {
          setEdit(false)
          props.clearCertificados()
          setHayCambios(false)
          setOpenModal(false)
        }}
        save={props.saveCertificados}
      />
      <Header
        history={props.history}
        userImageUrl={props.user.photoURL}
        userName={props.user.displayName}
        nombreProyecto={props.proyecto ? props.proyecto.nombre : ''}
        selectedKey={'proyecto'}
      />
      <div className={'tablesWrapper'}>
        <div className={'infoContainer'}>
          <InfoProyecto proyecto={props.proyecto} moneda={props.proyecto ? props.proyecto.moneda : "-"} />
          <div className='containerMesSemana'>
            <SelectorFiltro disabled={inputDisabled} onChange={handleInputs} filtro={filtro} />

            <SelectorMes disabled={edit} meses={meses} selected={mesSelected} onChange={(event) => onMesChange(event, true)} />

            <div className='infoSemana'>
            <IconButton
              className={`IconButtonWeek IconButtonWeekLeft ${edit || disableLastWeek ? "iconWeekDisable" : ""}`}
              sx={{ width: "2vw" }}
              title="Semana anterior"
              onClick={showLastWeek}
              disabled={edit || disableLastWeek}
            >
              <ChevronLeftIcon />
            </IconButton>

            <SelectorSemana disabled={edit} semanas={semanas} selected={semanaSelected} onChange={onSemanaChange} />

            <IconButton
              className={`IconButtonWeek IconButtonWeekRight ${edit || disableNextWeek ? "iconWeekDisable" : ""}`}
              sx={{ width: "2vw", marginLeft: "0.5vw" }}
              title="Semana siguiente"
              onClick={showNextWeek}
              disabled={edit || disableNextWeek}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        </div>
        </div>
        <div className='textButtonReporte'>
          <Text variant="xLarge">
            Certificación de horas
          </Text>
          <div className='buttonsContainer'>
            <GenerarReporteButton idProject={props.idProject !== null ? props.idProject : ''} nombreCliente={props.proyecto !== null ? props.proyecto.cliente : ''} fechaInicioProyecto={fechaInicioProyecto} fechaMax={fechaMax} semanaSelected={semanaSelected && format(new Date(semanaSelected.primerDia['_d']), 'yyyy-MM-dd')} />
            <ERPintegracionButton proyecto={props.proyecto} semana={semanaSelected}/>
          </div>
        </div>
        <div className={'certificacionContainer'}>
          <div style={{ display: 'flex', flex: 1}}>
            <TablaCertificacion
              planificacion={props.proyecto ? filtrarPlanificacion(props.proyecto.planificacion) : []}
              semana={semanaSelected}
              show={!!props.proyecto?.planificacion}
              mes={mesSelected}
              onChange={() => setHayCambios(true)}
              addCertificado={props.addCertificado}
              addCertificadoDevengamiento={props.addCertificadoDevengamiento}
              certificadosHoras={props.certificadosHoras}
              certificadosDevengamiento={props.certificadosDevengamiento}
              idProject={props.idProject}
              proyecto={props.proyecto}
              edit={edit}
              error={props.error}
              moneda={props.proyecto?.moneda}
              filtro={filtro}
            />
              <div style={{ display: "flex", alignItems: "center", padding: ".5rem" }}>
                <EditorTab
                  edit={edit}
                  hayCambios={hayCambios}
                  onClickGuardar={() => {
                    props.saveCertificados();
                    props.clearCertificados();
                    setHayCambios(false);
                    setEdit(false);
                  }}
                  onClickCancel={() => {
                    setEdit(false);
                    props.clearCertificados();
                    setHayCambios(false);
                    distpach(clearCargas());
                    distpach(clearComentario());
                  }}
                  onClickEdit={() => setEdit(true)}
                />
              </div>
          </div>
        </div>
        <div className={'totalesContainer'}>
          <div className={'totalRight'}>
            <div className={'totalProyectoInfoWrapper'}>
              <Text variant="xLarge">Acumulado del proyecto</Text>
              <MyToolTip />
              <Link href={getProjectUrl()} style={{ position: 'absolute', right: 0 }} target={'_blank'}>
                Ver en Project Online
              </Link>
            </div>
            <TablaBare
              error={props.error}
              meses={meses}
              mes={mesSelected}
              proyecto={props.proyecto}
              certificadosHoras={props.certificadosHoras}
              certificadosDevengamiento={props.certificadosDevengamiento}
              show={!!props.proyecto?.planificacion}
              filtro={filtro}
            />
          </div>
        </div>
      </div>
      <WidgetAccesos />
      {
        loaderPdf &&
        <div className='container-loaderPdf'>
          <LoaderPDF />
        </div>
      }
      {
        props.proyecto &&
        <ModalComentario saveCertificados={props.saveCertificados}
          clearCertificados={props.clearCertificados}
          setHayCambios={setHayCambios}
          proyecto={props?.proyecto}
          mesSelected={mesSelected}
          semanaSelected={semanaSelected} />
      }
    </div>
  );
};

export default Proyecto;

function showNextWeek() {
  throw new Error('Function not implemented.');
}
