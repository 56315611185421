import { USER_ROLES } from './../../../constants/index';
import * as types from '../../types/types';
import { getUserInfoThunks } from '../../../core/planificacionClient';
import { createAction } from '@reduxjs/toolkit';
import firebase from 'firebase';


export const getUserData = (payload) => {
    return async (dispatch: any, getState: any): Promise<any> => {
      let user = {
        email: payload.email,
        displayName: payload.displayName,
        photoURL: payload.photoURL,
        isAdmin:false,
        userToken:""};
        
      dispatch(setUser(user));
      dispatch(setUserLoading(false));
      const token = await  firebase.auth().currentUser.getIdToken()
      user = {...user,userToken:token}
      try {
        const userInfo = (await dispatch(getUserInfoThunks())).payload
        if(
          userInfo.rol === USER_ROLES.ADMIN || 
          userInfo.rol === USER_ROLES.CEO || 
          userInfo.rol === USER_ROLES.COO || 
          userInfo.rol === USER_ROLES.DIRECTOR_SBU){
          user.isAdmin = true;
        }
        
        dispatch(setUser(user));
      } catch (err) {
          console.log("Error 403")
      }
    }
  }
  
  export const setUser = createAction<any>(types.SET_USER)

  export const setUserLoading = createAction<any>(types.SET_USER_LOADING)