import { Link } from '@fluentui/react';
import React, { MouseEventHandler } from 'react';


interface NombreProyectoCellProps {
    idProject: string;
    nombre: string;
    onClick: MouseEventHandler<HTMLElement>;
    estado?: string;
}

export default function NombreProyectoCell(props: NombreProyectoCellProps){
    return (
        <div className='cell-normal-container'>
          <Link
            className={`${props.estado === "Cerrado" ? 'link-estado-cerrado' : ''}`}
            onMouseDown={props.onClick}
            title={'Ir al proyecto'}
            href={'/proyecto/' + props?.idProject}
            style={{ height: 'fit-content' }}>
            <span style={props.estado === "Cerrado" ? {color: "gray"} : {}}>
              {props.estado === "Cerrado" ? `${props?.nombre} [Cerrado]` : props?.nombre}
            </span>
          </Link>
        </div>
    );
}