export const ENV_VAR = {
  PRODUCCION: 'produccion',
  DESARROLLO: 'desarrollo',
};

export const PROJECT_ONLINE_URL = 'https://snoopconsulting.sharepoint.com/sites/pwa/project%20detail%20pages/schedule.aspx?projuid=';
export const URL_BASE = process.env.REACT_APP_ENV === ENV_VAR.PRODUCCION ? 'https://snoopgateway.azure-api.net/certificacion' : 'https://snoopgateway.azure-api.net/certificacion-dev';
export const PUBLIC_API_KEY = process.env.REACT_APP_ENV === ENV_VAR.PRODUCCION ? 'df6602a08fa64458855d03427f7c8faf': 'a54835f633f0442595d909da8830230e';
export const AUTH_HEADER = "Ocp-Apim-Subscription-Key";

export const concepto_tabla_proyecto = {
  CERTIFICADO: 'Certificado',
  TOGGL: 'Toggl',
  PLANIFICADO: 'Planificado',
};

export const USER_ROLES = {
  PM: "PM",
  CEO: "CEO",
  COO: "COO",
  DIRECTOR_SBU: "DIRECTOR_SBU",
  ADMIN: "ADMIN"
};