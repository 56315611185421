import React from 'react';
import DefaultCell from './DefaultCell';
import ClosedProjectButton from './ClosedProjectButton';
import { ItemTablaProyectos } from '../Inicio/TablaProyectos/TablaProyectosInterfaces';

interface TimeCellProps {
    value: any;
    estado?: string;
    column?: string;
    item?: ItemTablaProyectos;
    onClickProyecto?: any;
}

export default function TimeCell(props: TimeCellProps){
    let value:any;
    let className = 'cell-horas-container';

    //Si el proyecto está cerrado no muestro información.
    if(props.estado !== "Cerrado"){
        value = Number(props.value).toTime()
    }else{
        value = "";
        className = 'cell-cerrado-container';
    }

    return (
        <>
        <DefaultCell
        className={className}
        value={value} />
        {props.estado === "Cerrado" && props.column === "horasTrackeadasSinCertificar" && <ClosedProjectButton idProject={props.item?.idProject} 
        onClickProject={props.onClickProyecto}/>}
        </>
    );
}