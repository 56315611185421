import { useEffect } from 'react';

export default function useOnClickOutside(ref, callback) {
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
          callback();
        }
      };
      useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
          document.removeEventListener('click', handleClick);
        };
      });
}