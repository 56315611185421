import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginGoogle from '../components/Login/LoginGoogle';
import * as firebase from 'firebase';
import User from '../interfaces/User';
import { History } from 'history';
import { setUser } from '../redux/actions/usuario';

interface Props {
  setUser: (value: User) => void;
  user: User;
  history: History;
}

class LoginContainer extends Component<Props> {
  onClick() {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return <LoginGoogle onClick={this.onClick} />;
  }
}

const mapStateToProps = (state) => ({ user:state.generalReducer.user });
const mapDispatchToPropsActions = (dispatch: any) => ({
  setUser: (value: User) => dispatch(setUser(value)),
});
export default connect(mapStateToProps, mapDispatchToPropsActions)(LoginContainer);
