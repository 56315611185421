import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface Props{
    value: string;
    onChange: (any) => void;
}

const SelectorPeriodoIntegracion:React.FC<Props> = (props: Props) => {
    const handleChange = (event: SelectChangeEvent) => {
        props.onChange(event.target.value);
    };

    return (
        <div>
            <FormControl sx={{ marginRight: 5, width: 230}}>
                <InputLabel id="demo-simple-select-helper-label">Período</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={props.value}
                    label="Período"
                    onChange={handleChange}
                    sx={{height: 40}}
                >
                    <MenuItem value='Última certificacion'>Última certificacion</MenuItem>
                    <MenuItem value='Semana actual'>Semana actual</MenuItem>
                    <MenuItem value='Mes actual'>Mes actual</MenuItem>
                    <MenuItem value='Trimestre actual'>Trimestre actual</MenuItem>
                    <MenuItem value='Trimestre anterior'>Trimestre anterior</MenuItem>
                    <MenuItem value='Personalizado'>Personalizado</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

export default SelectorPeriodoIntegracion