import "antd/dist/antd.min.css"
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as firebase from 'firebase';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { loadTheme } from 'office-ui-fabric-react';
import theme from './theme/theme.json';
import extendsNumber from './extensions/number';

extendsNumber();
initializeIcons();

firebase.initializeApp({
  apiKey: 'AIzaSyCC7gVI5rvobkmJ3BqP83NXKgGGWHqOuc4',
  applicationId: 'certificacion-de-horas',
  projectId: 'certificacion-de-horas',
  authDomain: 'certificacion-de-horas.firebaseapp.com',
});

loadTheme({
  palette: theme,
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
