import moment from 'moment';
import * as types from '../../types/types'; 
import IProyecto from '../../../interfaces/IProyecto';
import IDiaCertificado from '../../../interfaces/IDiaCertificado';
import ISemanaCertificada from '../../../interfaces/ISemanaCertificada';
import { saveCertificationsThunks } from '../../../core/planificacionClient';
import { createAction } from '@reduxjs/toolkit';
import { setProyecto } from '../proyectos';
import ICertificadoHoras from '../../../interfaces/ICertificadoHoras';
import ICertificadoAIntegrar from '../../../interfaces/ICertificadoAIntegrar';
import { RootStateOrAny } from 'react-redux';

export const addCertificado = createAction<IDiaCertificado>(types.ADD_CERTIFICADO);
export const addCertificadoDevengamiento = createAction<ISemanaCertificada>(types.ADD_CERTIFICADO_DEVENGAMIENTO);
export const mergeCertificados = createAction<any>(types.MERGE_CERTIFICADOS);
export const clearCertificados = createAction(types.CLEAR_CERTIFICADOS);
export const saveComentario = createAction<object>(types.SAVE_COMENTARIOS);
export const clearComentario = createAction(types.CLEAR_COMENTARIOS);
export const saveCargas = createAction<any>(types.SAVE_CARGAS);
export const clearCargas = createAction(types.CLEAR_CARGAS);
export const loadingPdf = createAction<boolean>(types.SET_PDF_LOADING);
export const sendIntegracion = createAction<ICertificadoAIntegrar[]>(types.SEND_INTEGRACION);

const saveCertificadosLoading = createAction(types.SAVE_CERTIFICADOS_LOADING);
const saveCertificadosError = createAction<string>(types.SAVE_CERTIFICADOS_ERROR);

const updateCertificadosDevengamiento = (certificados: ISemanaCertificada[], proyecto: IProyecto): ISemanaCertificada[] => {
  certificados.forEach((certificado) => {
    const index = proyecto.certificados.certificadosDevengamiento.findIndex(
      (c) =>
        c.legajo === certificado.legajo &&
        moment(c.fecha).isSame(certificado.fecha, 'D') &&
        c.mes === certificado.mes &&
        moment(c.fecha).year() === moment(certificado.fecha).year()
    );

    if (index < 0) {
      proyecto.certificados.certificadosDevengamiento.push(certificado);
    } else {
      proyecto.certificados.certificadosDevengamiento[index] = certificado;
    }
  });

  return proyecto.certificados.certificadosDevengamiento;
};

const updateCertificadosHoras = (certificados: ICertificadoHoras[], proyecto: IProyecto): ICertificadoHoras[] => {
  certificados.forEach((certificado) => {
    const index = proyecto.certificados.certificadosHoras.findIndex(
      (c) => c.legajo === certificado.legajo && moment(c.fecha).isSame(certificado.fecha, 'd')
    );

    if (index < 0) {
      proyecto.certificados.certificadosHoras.push(certificado);
    } else {
      proyecto.certificados.certificadosHoras[index] = certificado;
    }
  });

  return proyecto.certificados.certificadosHoras;
};

export const saveCertificados = () => {
  return async (dispatch: any, getState: () => RootStateOrAny): Promise<void> => {
    dispatch(saveCertificadosLoading());

    const state = getState();
    const user = state.generalReducer.user;

    if (!user) {
      dispatch(saveCertificadosError('Not logged in'));
      return;
    }

    const proyecto: IProyecto = JSON.parse(JSON.stringify(state.generalReducer.proyecto));

    const certificadosHoras: ICertificadoHoras[] = state.generalReducer.certificadosHoras;
    const certificadosDevengamiento: ISemanaCertificada[] = state.generalReducer.certificadosDevengamiento;

    proyecto.certificados.certificadosHoras = updateCertificadosHoras(certificadosHoras, proyecto);
    proyecto.certificados.certificadosDevengamiento = updateCertificadosDevengamiento(certificadosDevengamiento, proyecto);

    // Ajustar mes en los certificados de devengamiento antes de enviarlos
    const certificadosDevengamientoParsed = certificadosDevengamiento.map((c) => ({
      ...c,
      mes: c.mes + 1,
    }));

    const argsSaveCertificados = {
      certificados: {
        certificadosDevengamiento: certificadosDevengamientoParsed,
        certificadosHoras,
      },
      comentarios: state.generalReducer.comentarios,
      horas: state.generalReducer.cargas
    };
    dispatch(saveCertificationsThunks(argsSaveCertificados));

    dispatch(setProyecto(proyecto));
  };
};


export const saveIntegracion = (body: ICertificadoAIntegrar[]) => {
  return async (dispatch: any, getState: any): Promise<any> =>{
    dispatch(sendIntegracion());
    const state = getState();
    const integracion = state.integracion;
    if(integracion === undefined || integracion === null){
      return /*error*/
    }
  }
};

export const resetIntegracionStatus = () => ({
  type: types.RESET_STATUS_INTEGRACION,
});