import * as types from '../../types/types'; 
import { getProyectosCoreThunk, getProyectoThunk } from '../../../core/planificacionClient';
import { createAction } from '@reduxjs/toolkit';

export const getProyectos = () => {
  return async (dispatch: any): Promise<any> => {
    dispatch(getProyectosCoreThunk())
  };
};

export const getProyecto = (idProject: string) => {
  return async (dispatch: any): Promise<any> => {
      dispatch(getProyectoThunk(idProject))
  };
};

export const setProyecto = createAction<any>(types.SET_PROYECTO)