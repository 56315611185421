import axios from 'axios';
import IProyecto from '../interfaces/IProyecto';
import { URL_BASE, PUBLIC_API_KEY, AUTH_HEADER, ENV_VAR } from '../constants';
import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase';
import ReduxState from '../interfaces/ReduxState'
import { format } from 'date-fns'

interface ClientBuilder {
    getProyectosCore: () => {};
    getProyecto: (idProject) => Promise<IProyecto>;
    saveCertifications: (certificadosDevengamientoParsed, certificadosHoras, comentarios) => {};
    getUserInfo: () => {};
}

interface State {
    generalReducer? : ReduxState
}

interface CertArgs {
    certificados: Certificados,
    comentarios: any,
    horas: any
}

interface Certificados {
    certificadosDevengamiento : any,
    certificadosHoras:any,
}

interface BodyPdf {
    desde?: string;
    hasta?: string;
    comentarios?: string;
    infoAdicional?: string;
    hora?: boolean;
    persona?: boolean;
    devengado?: boolean;
}

interface PdfArgs{
    idProject: string;
    dataPdfBody: BodyPdf;
}

interface CargaHoras{
    fecha: string,
    legajo: number,
    idProyecto: string,
    aprobado: boolean,
    horas: number
}

interface BodyIntegracion{
    descripcion: string,
    estadoValidacionKEY: string,
    integraciones: Integracion[]
}

interface Integracion{
    fecha: string,
    anio: number,
    mes: number,
    idProyecto: string
}

export default class PlanificacionClient implements ClientBuilder {
    private cliente
    constructor(token) {
        this.cliente = axios.create({
            headers: {
                [AUTH_HEADER]: PUBLIC_API_KEY,
                FirebaseAuth: token
            },
            baseURL: URL_BASE
        })
    }

    getProyectosCore = async (): Promise<IProyecto[]> => {
        const urlBases = `/api/proyectos`
        let res = await this.cliente.get(urlBases)
        return res.data
    }

    getProyectosCerradosInternosCore = async (cerrados : Boolean , internos : Boolean): Promise<IProyecto[]> => {
        const urlBases = `/api/proyectos?agregarProyectosCerrados=${cerrados}&agregarProyectosInternos=${internos}`
        let res = await this.cliente.get(urlBases)
        return res.data
    }



    getProyecto = async (idProject: string):Promise<IProyecto> => {
        const urlBases = `/api/proyectos/${idProject}`
        let res = await this.cliente.get(urlBases)
        return res.data
    }

    saveCertifications = async (certificados, comentarios, horas):Promise<any> => {
        return await this.cliente.post('/api/proyectos' , {certificados: certificados, comentarios: comentarios, horas: horas})
    } 

    getUserInfo = async ():Promise<any> => { 
        const urlBases = '/api/usuario/me'
        let res = await this.cliente.get(urlBases)
        return res.data
    }

    createPDF = async (idProject: string, dataPdfBody: BodyPdf, nombreCliente: string):Promise<any> =>{
        const dia = format(new Date(), "yyyy-MM-dd")
        const res = await this.cliente.post(`/api/pdf/${idProject}`, dataPdfBody, {responseType: 'arraybuffer'})
        const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/pdf"}))
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Certificacion ${nombreCliente} ${dia}.pdf`);
        document.body.appendChild(link);
        link.click();

        return link
    }

    getReportes = async (idProject: string):Promise<any> =>{
        const res = await this.cliente.get(`/api/Pdf/ReportesPrevios?idProject=${idProject}`)
        return res.data
    }

    getDescargaReporte = async (idReporte: number):Promise<any> =>{
        const res = await this.cliente.get(`/api/Pdf/ReportesPrevios/${idReporte}`)
        const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/pdf"}))
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Reporte Certificacion.pdf`);
        document.body.appendChild(link);
        link.click();

        return link
    }

    sendIntegracion = async (bodyIntegracion: BodyIntegracion) =>{
        return await this.cliente.post('/api/proyectos/DespachoVenta', bodyIntegracion);
    }
};

export const getProyectosCoreThunk = createAsyncThunk("generalReducer/getProyectosCore", 
async (arg,{getState}) => {
    const state:State = getState()
    let userToken = state.generalReducer.user.userToken ? state.generalReducer.user.userToken: (await firebase.auth().currentUser.getIdTokenResult()).token
    const client = new PlanificacionClient(userToken)
    try {
        const res = await client.getProyectosCore()
        return res
    } catch (err) {
        throw new Error(JSON.stringify(err.response));
    }
})

export const getProyectoThunk = createAsyncThunk("generalReducer/getProyecto",
async (idProject:string,{rejectWithValue,getState}) => {
    const state:State = getState()
    let userToken = state.generalReducer.user.userToken ? state.generalReducer.user.userToken: (await firebase.auth().currentUser.getIdTokenResult()).token
    const client = new PlanificacionClient(userToken)
    try {
        const res = await client.getProyecto(idProject)
        return res
    } catch (err) {
        throw new Error(JSON.stringify(err.response));
    }
})


export const saveCertificationsThunks = createAsyncThunk(
    "generalReducer/saveCertifications",
    async (certArgs: CertArgs, { rejectWithValue, getState }) => {
      const { certificados, comentarios, horas } = certArgs;
  
      const state: State = getState();
      let userToken = state.generalReducer.user.userToken
        ? state.generalReducer.user.userToken
        : (await firebase.auth().currentUser.getIdTokenResult()).token;
  
      const client = new PlanificacionClient(userToken);
  
      try {
        const response = await client.saveCertifications(certificados, comentarios, horas);
  
        return {
          data: response.data,
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
        };
      } catch (err: any) {
        return rejectWithValue(err.response?.data || "Error en la solicitud");
      }
    }
  );  

export const getUserInfoThunks = createAsyncThunk("generalReducer/getUserInfo",
async (args,{getState}) => {
    const state:State = getState()
    let userToken = state.generalReducer.user.userToken ? state.generalReducer.user.userToken: (await firebase.auth().currentUser.getIdTokenResult()).token
    const client = new PlanificacionClient(userToken)
    try {
        const res = await client.getUserInfo()
        return res
    } catch (err) {
        throw new Error(JSON.stringify(err.response));
    }
})

export const getReporteThunk = createAsyncThunk("generalReducer/getReportes",
async (idProject:string,{rejectWithValue,getState}) => {
    const state:State = getState()
    let userToken = state.generalReducer.user.userToken ? state.generalReducer.user.userToken: (await firebase.auth().currentUser.getIdTokenResult()).token
    const client = new PlanificacionClient(userToken)
    try {
        const res = await client.getReportes(idProject)
        return res
    } catch (err) {
        throw new Error(JSON.stringify(err.response));
    }
})

export const sendIntegracionThunk = createAsyncThunk("generalReducer/sendIntegracion",
async (body: BodyIntegracion, {getState}) =>{
    const state:State = getState()
    let userToken = state.generalReducer.user.userToken ? state.generalReducer.user.userToken: (await firebase.auth().currentUser.getIdTokenResult()).token
    const client = new PlanificacionClient(userToken)
    try {
        const res = await client.sendIntegracion(body)
        const data = res.data;
        return data;
    } catch (err) {
        throw new Error(JSON.stringify(err.response));
    }
}
)