import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    send: () => void;
}

const WarningValidationModal = (props: Props) => {
    const [countdown, setCountdown] = useState(5);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (props.open) {
            setCountdown(5);
            setIsDisabled(true);
            
            const interval = setInterval(() => {
                setCountdown((prev) => {
                    if (prev === 1) {
                        clearInterval(interval);
                        setIsDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [props.open]);

    const handleOkOption = () => {
        props.send();
        props.setOpen(false);
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Confirmación de certificación por el cliente"}
            </DialogTitle>
            <DialogContent sx={{ minHeight: "1rem" }}>
                <DialogContentText id="alert-dialog-description">
                    Estás a punto de marcar esta integración como <strong>certificada por el cliente</strong>.  
                    Selecciona esta opción solo si el cliente ha confirmado su aprobación. 
                    <br />
                    <br />
                    <strong>⚠️Advertencia: </strong>  
                    Una vez enviada, esta acción no podrá deshacerse. Asegúrate de que el cliente ha dado su confirmación final antes de continuar.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: "1rem" }}>
                <Stack direction="row" spacing={2} sx={{ margin: "0 auto" }}>
                    <Button 
                        size="large"
                        variant="contained"
                        onClick={handleOkOption}
                        disabled={isDisabled}
                        startIcon={isDisabled ? <CircularProgress size={20} color="inherit" /> : null}
                    >
                        {isDisabled ? `Enviar integración (${countdown}s)` : "Enviar integración"}
                    </Button>
                    <Button
                        sx={{ color: "#444444", fontWeight: "600" }}
                        size="medium"
                        onClick={() => props.setOpen(false)}
                    >
                        No enviar integración
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default WarningValidationModal;