import React, { useEffect, useState } from "react";
import { URL_BASE } from "../../../constants";

interface Props {
    
}

export default function EnvironmentViewer(props: Props){
    const [activo, setActivo] = useState(false);
    let message = "DEV"
    const url = URL_BASE;

    useEffect(()=>{
        if(url.includes("dev")){
            setActivo(true);
        }
    },[url]);

    const style = {
        backgroundColor: "#2f96c2",
        color: "white",
        margin: "auto",
        padding: ".2rem .4rem",
        fontWeight: "400"
    }

    return(
        <div style={activo ? style : {}}>
            {activo ? <h2 style={style}>BBDD: {message}</h2> : ""}
        </div>
    )
}