import { Depths } from '@uifabric/fluent-theme';
import React from 'react';
import { ActionButton } from 'office-ui-fabric-react';
import './Options.css';
import firebase from 'firebase';
export interface OptionsProps {
    show: boolean;
    ref: any;
}

const Options: React.FC<OptionsProps> = React.forwardRef((props, ref: any) => {
    const cerrarSesion = () => {
        firebase.auth().signOut().catch((error) => {
            console.log(error);
        });
    }

    if(props.show === false) return null;

    return (
        <div ref={ref} className={"options-main-container"} style={{ boxShadow: Depths.depth4 }}>
            <ActionButton  allowDisabledFocus onClick={cerrarSesion}>
                Cerrar sesión
            </ActionButton>
        </div>
    );
});

export default Options;