import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { Text ,ITextProps} from '@fluentui/react/lib/Text';
import logo from '../../assets/lifeguard-snoop-logo.png'
import './LoaderProyecto.css'

interface Props {
  open: boolean;
  text?: string;
}

function LoaderProyecto(props: Props): JSX.Element {
  return (
    <Backdrop className="backDropLoader" style={{backgroundColor: 'rgba(255, 255, 255, 0.35)',display:'flex',flexDirection:"column", position:"absolute", margin: "0", zIndex: '1000'}} open={props.open}>
      <img src={logo} alt="Logo" className='snoop-spinner' />
      <Text variant={"xLarge" as ITextProps['variant']}>{props.text ? props.text : 'Obteniendo datos del proyecto...'}</Text>
    </Backdrop>
  );
}

export default LoaderProyecto;
