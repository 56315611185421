import React from "react";
import { ItemTablaProyectos } from "../Inicio/TablaProyectos/TablaProyectosInterfaces";

interface ClosedProjectButtonProps {
    idProject?: string;
    onClickProject?: (e: React.MouseEvent<HTMLButtonElement>, idProject?: string) => void; 
}

export default function ClosedProjectButton(props: ClosedProjectButtonProps){
    
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        window.location.replace("/proyecto/"+props?.idProject);
        // props.onClickProject?.(e, props.idProject); // Pasamos el evento primero, seguido del idProject
      };

    return(
        <button className="closed-project-button" onClick={handleClick}>Ingresar para ver información</button>
    );
}