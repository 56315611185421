import React from 'react';
import { Forbidden } from '../components/Forbidden/Forbidden';
import User from '../interfaces/User';
import { History } from 'history';
import { connect } from 'react-redux';

interface Props {
  history: History;
  user: User;
}

const ForbiddenContainer: React.FC<Props> = (props: Props) => {
  return <Forbidden user={props.user} history={props.history} />;
};

const mapStateToProps = (state) => ({
  user:state.generalReducer.user,
});
export default connect(mapStateToProps)(ForbiddenContainer);
