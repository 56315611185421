import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setProyecto } from '../redux/actions/proyectos/index';
import Proyecto from '../components/Proyecto/Proyecto';
import { History } from 'history';
import User from '../interfaces/User';
import IProyecto from '../interfaces/IProyecto';
import { getProyecto, getProyectos } from '../redux/actions/proyectos/index';
import IDiaCertificado from './../interfaces/IDiaCertificado';
import ISemanaCertificada from './../interfaces/ISemanaCertificada';
import IMes from './../interfaces/IMes';
import ISemana from '../interfaces/ISemana';
import LoaderProyecto from '../components/LoaderProyecto/LoaderProyecto';
import { addCertificado, saveCertificados, addCertificadoDevengamiento, clearCertificados } from '../redux/actions/certificados/index';
import { setUser } from '../redux/actions/usuario';
import ErrorStatus from '../interfaces/ErrorStatus';
import ErrorComponent from '../components/Error/Error';
import { getProyectoThunk } from '../core/planificacionClient';

interface Props {
  user: User;
  proyecto: IProyecto;
  proyectos: Array<IProyecto>;
  certificadosHoras: Array<IDiaCertificado>;
  history: History;
  setUser: (value: User) => void;
  getProyecto: (idProject: string) => void;
  getProyectos: () => void;
  setProyecto: (p: IProyecto) => void;
  addCertificado: (c: IDiaCertificado) => void;
  addCertificadoDevengamiento: (c: ISemanaCertificada) => void;
  saveCertificados: () => void;
  idProject: string;
  clearCertificados: () => void;
  certificadosDevengamiento: Array<ISemanaCertificada>;
  search: any;
  fetchingProyecto: boolean;
  statusCode: ErrorStatus;
}

class ProyectoContainer extends Component<Props> {
  componentDidMount() {
    if (!Array.isArray(this.props.proyectos) || this.props.proyectos.length === 0) {
      if (this.props.history.location.pathname === '/') {
           this.props.getProyectos();}
    }
    this.props.getProyecto(this.props.idProject);
  }

  onProyectoChange(id) {
    this.props.clearCertificados();
    this.props.getProyecto(id);
    this.props.setProyecto(this.props.proyectos.find((p) => p.idProject === id));
  }

  getMesFromQuery() {
    return new URLSearchParams(this.props.search).get('mes');
  }
  getSemanaFromQuery() {
    return new URLSearchParams(this.props.search).get('semana');
  }

  updateQuery(mes: IMes, semana: ISemana) {
    const query = new URLSearchParams(this.props.search);
    query.set('semana', semana.primerDia.isoWeek().toString());
    query.set('mes', mes.primerDia.format('MM-YYYY'));
    this.props.history.push({
      search: '?' + query.toString(),
    });
  }
  
  render() {
    if (this.props.statusCode.statusCode === 403) {
      this.props.history.push('/forbidden');
      return null;
    }

    if (this.props.user === null || this.props.user === undefined) {
      this.props.history.push('/login');
      return null;
    } else {
      return (
        <>
          
          {
          this.props.statusCode.statusCode === null && this.props.statusCode.project !== true  
          ?
          <LoaderProyecto open={this.props.fetchingProyecto} />
          :
          <ErrorComponent  statusCode={this.props.statusCode} idProject={this.props.idProject}  />
          }
          <Proyecto
            user={this.props.user}
            history={this.props.history}
            proyecto={this.props.proyecto}
            proyectos={this.props.proyectos}
            onProyectoChange={this.onProyectoChange.bind(this)}
            idProject={this.props.idProject}
            addCertificado={this.props.addCertificado}
            certificadosHoras={this.props.certificadosHoras}
            certificadosDevengamiento={this.props.certificadosDevengamiento}
            saveCertificados={this.props.saveCertificados}
            clearCertificados={this.props.clearCertificados}
            addCertificadoDevengamiento={this.props.addCertificadoDevengamiento}
            semanaInicial={this.getSemanaFromQuery()}
            mesInicial={this.getMesFromQuery()}
            updateQuery={this.updateQuery.bind(this)}
            error={this.props.statusCode}
          />
        </>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.generalReducer.user,
  proyecto: state.generalReducer.proyecto,
  proyectos: state.generalReducer.proyectos,
  certificadosHoras: state.generalReducer.certificadosHoras,
  certificadosDevengamiento: state.generalReducer.certificadosDevengamiento,
  fetchingProyecto: state.generalReducer.fetchingProyecto,
  statusCode: state.generalReducer.statusCode,
});

const mapDispatchToPropsActions = (dispatch: any) => ({
  setUser: (value: User) => dispatch(setUser(value)),
  getProyecto: (idProject: string) => dispatch(getProyectoThunk(idProject)),
  getProyectos: () => dispatch(getProyectos()),
  setProyecto: (p: IProyecto) => dispatch(setProyecto(p)),
  addCertificado: (c: IDiaCertificado) => dispatch(addCertificado(c)),
  addCertificadoDevengamiento: (c: ISemanaCertificada) => dispatch(addCertificadoDevengamiento(c)),
  saveCertificados: () => dispatch(saveCertificados()),
  clearCertificados: () => dispatch(clearCertificados()),
});

export default connect(mapStateToProps, mapDispatchToPropsActions)(ProyectoContainer);
