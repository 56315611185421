import React from 'react';
import './Loader.css';
import { Text ,ITextProps} from 'office-ui-fabric-react/lib/Text';
import logo from '../../../assets/lifeguard-snoop-logo.png'

const Loader: React.FC<{ show: boolean }> = ({ show }: { show: boolean }) => {
  return show ? (
    <div className="loader-container">
      <img src={logo} alt="Logo" className='snoop-spinner' />
      <Text variant={"xLarge" as ITextProps['variant']}>Obteniendo Proyectos</Text>
    </div>
  ) : null;
};

export default Loader;
