import React, { useState } from 'react';
import './Header.css';
import logoSnoop from '../../assets/logo snoop.svg';
import Avatar from '@mui/material/Avatar';
import { History } from 'history';
import { Text } from '@fluentui/react/lib/Text';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import { Depths } from '@uifabric/fluent-theme/lib/fluent/FluentDepths';
import Options from './Options/Options';
import useOnClickOutside from './Hooks/useOnClickOutside';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchBar from '../SearchBar/SearchBar';
import EnvironmentViewer from './EnvironmentViewer/EnvironmentViewer';

interface Props {
  nombreProyecto?: string;
  history: History;
  userName: string;
  userImageUrl: string;
  selectedKey?: string;
  handleChangeSearch?: any
}

const Header: React.FC<Props> = (props: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const nombreProyecto = props.nombreProyecto || '';
  const optionsRef = React.createRef();
  useOnClickOutside(optionsRef, () => setShowOptions(false));

  const onPressInicio = () => {
    props.history.push('/');
  };

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  }

  const onLinkClick = (item: PivotItem) => {
    switch (item.props.itemKey) {
      case 'proyecto':
        break;
      case 'inicio':
        onPressInicio();
        break;
    }
  };

  return (
    <div className="header-container" style={{ boxShadow: Depths.depth4, zIndex: 5 }}>
      <div className="header-wrapper">
        <img className="header-logo" src={logoSnoop} alt="Logo snoop" onClick={onPressInicio}/>
        <Text className="header-title">Certificación de horas</Text>
        <Pivot onLinkClick={onLinkClick} selectedKey={props.selectedKey || undefined}>
          <PivotItem onClick={onPressInicio} headerText={'Inicio'} itemKey={'inicio'} />
          {nombreProyecto !== undefined && nombreProyecto !== '' ? (
            <PivotItem headerText={''} itemKey={'proyecto'}/>
          ) : null}
        </Pivot>
        <EnvironmentViewer />
        <div className="header-avatar-container">
          <div onClick={toggleShowOptions} className="header-avatar-wrapper">
            <Text className="header-nombre-pm">{props.userName ? props.userName : ''}</Text>
            <Avatar className="header-avatar" alt="Avatar" src={props.userImageUrl} />
            <KeyboardArrowDownIcon className={showOptions ? "header-arrow-up" : "header-arrow-down"} />
          </div>
          <Options ref={optionsRef} show={showOptions}/>
        </div>
      </div>
    </div>
  );
};

export default Header;
