import React from "react";
import { IconButton } from "@mui/material";
import EditButton from "../EditButton/EditButton";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
  edit: boolean;
  hayCambios: boolean;
  onClickEdit: () => void;
  onClickGuardar: () => void;
  onClickCancel: () => void;
}

export default function EditorTab({ edit, hayCambios, onClickCancel, onClickEdit, onClickGuardar }: Props) {
  return !edit ? (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <EditButton onClick={onClickEdit} />
    </div>
  ) : (
    <div
      style={{
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        gap: "15px"
      }}
    >
      {/* Botón Guardar Cambios */}
      <IconButton
        onClick={onClickGuardar}
        sx={{
          backgroundColor: "#BE0411",
          color: "white",
          width: "40px",
          height: "40px",
          "&:hover": { backgroundColor: "#a0030e" },
        }}
      >
        <SaveRoundedIcon />
      </IconButton>

      {/* Botón Descartar Cambios */}
      <IconButton
        onClick={onClickCancel}
        sx={{
          backgroundColor: "white",
          color: "#BE0411",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          "&:hover": { backgroundColor: "#f8d7da" },
        }}
      >
        <ClearIcon/>
      </IconButton>
    </div>
  );
}
