import React, { FC } from 'react';
import Selector from '../../Selector/Selector';

interface Props {
    disabled: string;
    onChange: (event: any) => void;
    filtro: string;
}

const SelectorFiltro: FC<Props> = (props: Props) => {
    let filtros = ["Todos", "PTMs", "Snoopies"]

    if(props.filtro.length > 0){
        filtros = filtros.filter(filtro => filtro.toLowerCase() !== props.disabled)
    }

    const onChangeFiltro = (e, filtro) => {
        props.onChange(filtro);
    };

    return (
        <Selector
            disabled={false}
            style={{ marginLeft: '2vw', minWidth: '150px' }}
            width={'10vw'}
            label={'Filtrar Por'}
            list={filtros.map((filtro) => ({ value: filtro, id: filtro }))}
            selected={props.filtro ? { id: props.filtro } : null}
            onChange={onChangeFiltro}
        />
    );
};

export default SelectorFiltro;