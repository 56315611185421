import React from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  onClick: () => void;
}

const EditButton: React.FC<Props> = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: "#BE0411",
        color: "white",
        transition: "0.2s",
        "&:hover": {
          backgroundColor: "#a0030e",
        },
        width: 40,
        height: 40,
      }}
    >
      <EditIcon />
    </IconButton>
  );
};

export default EditButton;
