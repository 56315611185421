import React from 'react';
import { TextField, IconButton } from '@mui/material'; // Importamos los componentes de MUI
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Importamos el ícono de MUI
import IMes from '../../../../interfaces/IMes';
import ISemana from '../../../../interfaces/ISemana';

interface Props {
  mes: IMes;
  semana: ISemana;
  column: any;
  isIntegrated: boolean;
}

export const IntegradoField = (props: Props): JSX.Element => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {props.isIntegrated && (
        <IconButton style={{ marginLeft: 8, color: '#017BE1' }}>
          <CheckCircleIcon />
        </IconButton>
      )}
    </div>
  );
};
