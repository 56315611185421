import React, { Component, EventHandler } from 'react';
import GoogleButton from 'react-google-button';
import './LoginGoogle.css';
import logo from '../../assets/logo snoop.svg';
import { Text } from 'office-ui-fabric-react/lib/Text';
interface Props {
  onClick: EventHandler<any>;
}

class LoginGoogle extends Component<Props> {
  render() {
    return (
      <div className="login-google-container">
        <img className="login-google-logo" src={logo} alt={'logo'} />
        <div className="login-google-wrapper">
          <Text className="login-google-title" variant={'xxLarge'}>
            Certificación de horas
          </Text>
          <Text variant={'xLarge'} style={{ marginBottom: '10px' }}>
            Iniciar sesión
          </Text>
          <GoogleButton style={{ width: '20vw', minWidth: '350px' }} onClick={this.props.onClick} />
          <Text className="login-google-footer">(*) Recorda usar tu cuenta de Google de Snoop consulting</Text>
        </div>
      </div>
    );
  }
}

export default LoginGoogle;
