import React from 'react';
import { AnimatedTimeText } from '../AnimatedText/AnimatedTimeText';

interface AnimatedTimeCellProps {
    value: any;
    bold?: boolean;
}

export default function AnimatedTimeCell({value, bold = false}: AnimatedTimeCellProps) {
    let style: any = {};
    if(bold) style.fontWeight = 'bold';

    return (
        <div className={'cell-horas-bare'} style={style}>
            <AnimatedTimeText value={value} />
        </div>
    );
}