import React, { useState, useEffect } from 'react';
import TablaProyectos from './TablaProyectos/TablaProyectos';
import WelcomeTitle from './WelcomeTitle/WelcomeTitle';
import IProyecto from '../../interfaces/IProyecto';
import { SBU_OPTIONS } from './Filtros/FiltroSBU';
import FiltroSBU from './Filtros/FiltroSBU';
import User from '../../interfaces/User';
import Header from '../Header/Header';
import Loader from './Loader/Loader';
import { History } from 'history';
import './Inicio.css';
import { useSelector } from 'react-redux';
import PlanificacionClient from '../../core/planificacionClient';
import firebase from 'firebase';
import ErrorComponent from '../Error/Error'
import SearchBar from '../SearchBar/SearchBar';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import WidgetAccesos from '../WidgetAccesos/WidgetAccesos';
import { useLocation } from "react-router-dom";
import { set } from 'date-fns';
import { el } from 'date-fns/locale';

interface Props {
  user: User;
  history: History;
  proyectos: Array<IProyecto>;
  setProyecto: (p: IProyecto) => void;
}

export default function Inicio(props: Props) {
  const [search, setSearch]: any = useState([])
  const [sbuFilter, setSbuFilter] = useState(SBU_OPTIONS.TODOS);
  const [proyectosCerrados, setProyectosCerrados]: any = useState([])
  const [proyectosInternos, setProyectosInternos]: any = useState([])
  const[proyectosCerradosInternos, setProyectosCerradosInternos]: any = useState([])
  const [checkCerrados, setCheckCerrados] = useState(false)
  const [checkInternos, setCheckInternos] = useState(false)
  const allSbus = props.proyectos ? props.proyectos.map(proyecto => proyecto.sbu) : []
  const query = new URLSearchParams(useLocation().search);
  const getParamProyecto = query.get('q')
  const [proyectos, setProyectos] = useState({ cerrados: false, internos: false });

  useEffect(()=>{
    if(getParamProyecto){
      if(props.proyectos.length !== 0){
        handleChangeSearch(getParamProyecto ? {target:{ value: getParamProyecto}} : '')
      }
    }
  }, [props.proyectos])

  
  useEffect(() => {
    const fetchProyectos = async () => {
      if(proyectos.cerrados && !proyectos.internos && proyectosCerrados.length === 0){
        
        setProyectosCerrados(await GetProyectosCerradosInternos())
      } else if(proyectos.internos && !proyectos.cerrados && proyectosInternos.length === 0){
      
        setProyectosInternos(await GetProyectosCerradosInternos())
      }else if(proyectos.cerrados && proyectos.internos && proyectosCerradosInternos.length === 0){

        setProyectosCerradosInternos(await GetProyectosCerradosInternos())
      }
    };
    fetchProyectos();
  }, [proyectos.cerrados, proyectos.internos])

  useEffect(()=>{
    if (sbuFilter === SBU_OPTIONS.PROYECTO_INTERNO){
      setProyectos(prevState => ({ ...prevState, internos: true }));
    }
  }, [sbuFilter])



  const onClickProyecto = (idProject: string, proyecto: IProyecto): void => {
    props.setProyecto(proyecto);
    //props.history.push('/proyecto/' + idProject);
  };

  const onSBUFilterChange = (filterKey: string): void => {
    setSbuFilter(filterKey);
  }

  const handleChangeSearch = (event) => {
    if (event.target.value.length === 0) {
      setSearch([]);
    } else {
      if (proyectos.cerrados && proyectos.internos) {
        filterSearch(proyectosCerradosInternos, event.target.value);
      } 
       else if (proyectos.cerrados) {
      filterSearch(proyectosCerrados, event.target.value);
      } else if (proyectos.internos) {
      filterSearch(proyectosInternos, event.target.value);
      } else {
      filterSearch(props.proyectos, event.target.value);
      } 
    }
  }

  const filterSearch = (proyectos, dataBuscada) => {
    const resultadoBusqueda = proyectos.filter((info) => 
      (info.nombre !== null ? info.nombre.toLowerCase().includes(dataBuscada.toLowerCase()) : undefined) || 
      (info.pm !== null ? info.pm.toLowerCase().includes(dataBuscada.toLowerCase()) : undefined) || 
      (info.cliente !== null ? info.cliente.toLowerCase().includes(dataBuscada.toLowerCase()) : undefined) || 
      (info.sbu !== null ? info.sbu.toLowerCase().includes(dataBuscada.toLowerCase()) : undefined))

    resultadoBusqueda.length !== 0 ? setSearch(resultadoBusqueda) : setSearch([])
  }

  const filterProyectos = (proyectos) => {
    if (Array.isArray(proyectos) && sbuFilter !== SBU_OPTIONS.TODOS) {
      if (sbuFilter === SBU_OPTIONS.PROYECTO_INTERNO) {
        return proyectos.filter(proyecto => proyecto.tipo == "Proyecto Interno");
      }
      return proyectos.filter(proyecto => proyecto.sbu === sbuFilter);
    }

    return proyectos;
  }

  const GetProyectosCerradosInternos = async () =>{
    const firebaseToken = await firebase.auth().currentUser.getIdTokenResult()
    const cliente = new PlanificacionClient(firebaseToken.token)
    const dataProyectos = await cliente. getProyectosCerradosInternosCore(proyectos.cerrados,proyectos.internos)
    return dataProyectos
  }


  const handleClickProyectosCerrados = async () =>{
    setCheckCerrados(!checkCerrados)
    proyectos.cerrados = !proyectos.cerrados
  }

  const handleClickProyectosInternos = async () =>{
    setCheckInternos(!checkInternos)
    proyectos.internos = !proyectos.internos
}

  const getProyectos = () => {
    if(proyectos.cerrados && proyectos.internos){
      if(search.length !== 0){
        return search
      } else {
        return filterProyectos(proyectosCerradosInternos)
      }
    } else if(proyectos.cerrados){
        if(search.length !== 0){
           return search
        } else {
          return filterProyectos(proyectosCerrados)
        }
    } else if(proyectos.internos){
      if(search.length !== 0){
        return search
      } else {
        return filterProyectos(proyectosInternos)
      }
    } else {
      if(!proyectos.internos && !proyectos.cerrados){
        if(search.length !== 0){
          return search
        } else{
          return filterProyectos(props.proyectos);}
      }
    }
  }

    
  const filtrosSbusPersona = (array) =>{
    const sbuUnicos = [];

    array.forEach(sbu => {
      if (!sbuUnicos.includes(sbu) && sbu !== null) {
        sbuUnicos.push(sbu);
      }
    });

    return sbuUnicos
  }

  const projectsError: any = useSelector<any>(state => state.generalReducer.statusCode)

  return (
    <div className="inicio-main-container">
      <Header history={props.history} userName={props.user.displayName} userImageUrl={props.user.photoURL}/>
      <div className="inicio-body">
        <div className="inicio-left-container">
          <div className='inicio-search'>
            <SearchBar handleChangeSearch={handleChangeSearch} getParamProyecto={getParamProyecto}/>
            <div className="inicio-filters-container">
              <WelcomeTitle userName={props.user.displayName} />
              <div className="inicio-filters">
                <div className="check-proyectos-cerrados" onClick={handleClickProyectosCerrados}>
                  {proyectos.cerrados ? <ToggleOnIcon className="icon-check-cerrados icon-check-cerrados-on" /> : <ToggleOffIcon className="icon-check-cerrados icon-check-cerrados-off" />}
                  Ver proyectos cerrados
                </div>
                <div className="check-proyectos-internos" onClick={handleClickProyectosInternos}>
                  {proyectos.internos ? <ToggleOnIcon className="icon-check-cerrados icon-check-cerrados-on" /> : <ToggleOffIcon className="icon-check-cerrados icon-check-cerrados-off" />}
                  Ver proyectos internos
                </div>
                  {filtrosSbusPersona(allSbus).length > 1 ? <FiltroSBU proyectos={props.proyectos} SBU={sbuFilter} onSBUChange={onSBUFilterChange} /> : null}
              </div>
          </div>
          </div>
          {
            projectsError.statusCode !== null && projectsError.statusCode !== 204 && projectsError.project === false
              ?
              <ErrorComponent statusCode={projectsError} />
              :
              null
          }
          <TablaProyectos error={projectsError} onClickProyecto={onClickProyecto} proyectos={getProyectos()}/>
          <Loader show={(props.proyectos.length < 1 && projectsError.statusCode === null) || (proyectos.cerrados && proyectosCerrados.length === 0  && proyectosCerradosInternos.length === 0)
          || (proyectos.internos && proyectosInternos.length === 0 && proyectosCerradosInternos.length === 0) || (proyectos.cerrados && proyectos.internos && proyectosCerradosInternos.length === 0)||(proyectos.cerrados && !proyectos.internos && proyectosCerrados.length === 0)||(proyectos.internos && !proyectos.cerrados && proyectosInternos.length === 0)
          } />
        </div>
      </div>
      <WidgetAccesos/>
    </div>
  );}


