import * as React from 'react';
import './TablaProyectos.css';
import CurrencyCell from '../../TableCells/CurrencyCell';
import BaseTable from '../../BaseTable/BaseTable';
import IProyecto from '../../../interfaces/IProyecto';
import columnsConfig from './tablaInicioConfig.json';
import DefaultCell from '../../TableCells/DefaultCell';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { ItemTablaProyectos, TablaProyectoProps, TablaProyectosState } from './TablaProyectosInterfaces';
import NombreProyectoCell from '../../TableCells/NombreProyectoCell';
import FechaUltimaCertificacionCell from '../../TableCells/FechaUltimaCertificacionCell';
import TimeCell from '../../TableCells/TimeCell';
import TableError from '../../TableError/TableError';
import SbuProyectoCell from '../../TableCells/SbuProyectoCell';
import PmCell from '../../TableCells/PmCell';

export default class TablaProyectos extends React.Component<TablaProyectoProps, TablaProyectosState, any> {
  constructor(props: TablaProyectoProps) {
    super(props);

    this.state = {
      columns: this._buildColumns(),
    };
  }
  public render(): JSX.Element {
    const items = this.buildItems(this.props.proyectos);
    const groups = this.buildGroups(items);
    const { columns } = this.state;
    
    return (
      <>{
        this.props.error?.statusCode === 204 ?
        <TableError empty={true} />
        :
        <BaseTable
          error={this.props.error ? this.props.error : null}
          rowHeigth={"40px"}
          defaultColumnSortedKey='horasTrackeadasSinCertificar'
          items={items}
          columns={columns}
          groups={groups}
          onRenderItemColumn={this._renderItemColumn.bind(this)}
          moneda={this.props.proyectos?.find((p) => p.idProject === items[0].idProject)?.moneda}
          filtro="Todos"
        />
      }
      </>
    );
  }

  private buildItems = (proyectos: IProyecto[]) => {
    let proyectosFacturables: any = proyectos !== undefined ? proyectos.filter((p) => p.tipo === 'Proyecto Facturable') : []
    let proyectosInternos = proyectos !== undefined ?  proyectos.filter((p) => p.tipo !== 'Proyecto Facturable') : []
    return [proyectosFacturables, proyectosInternos];
  };

  private buildGroups = (items: any) => {
    const cantidadFacturables = items[0].length;
    const cantidadInternos = items[1].length;
    return [
      {
        key: 'proyectosFacturables',
        name: 'Proyectos Facturables',
        startIndex: 0,
        count: cantidadFacturables
      },
      {
        key: 'proyectosInternos',
        name: 'Proyectos Internos',
        startIndex: cantidadFacturables,
        count: cantidadInternos
      },
    ];
  };

  private onClickProyecto = (e: any, item: ItemTablaProyectos) => {
    if (e.button !== 1) {
      this.props.onClickProyecto(
        item.idProject,
        this.props.proyectos.find((p) => p.idProject === item.idProject)
      );
      return false;
    }
  };

  private _buildColumns(): IColumn[] {
    let columns = columnsConfig;
    return columns;
  }

  private _renderItemColumn(item: ItemTablaProyectos, index: number, column: IColumn) {
    const fieldContent = item[column.fieldName as keyof ItemTablaProyectos] as string;
    switch (column.key) {
      case 'sbuProyecto':
        return <SbuProyectoCell value={fieldContent}/>
      case 'nombre':
        return <NombreProyectoCell onClick={e => this.onClickProyecto(e, item)} nombre={fieldContent} idProject={item.idProject} estado={item.estado}/>
      case 'pm':
        return <PmCell value={fieldContent}/>
      case 'fechaUltimaCertificacion':
        return <FechaUltimaCertificacionCell value={fieldContent} estado={item.estado}/>
      case 'horasCertificadas':
        return <TimeCell value={fieldContent} estado={item.estado}/>;
      case 'horasPlanificadas':
        return <TimeCell value={fieldContent} estado={item.estado}/>;
      case 'horasTrackeadasSinCertificar':
        return <TimeCell value={fieldContent} 
        estado={item.estado} 
        column={column.key} 
        item={item} 
        onClickProyecto={this.props.onClickProyecto}
        />;
      case 'devengamientoCertificado':
        return <CurrencyCell value={fieldContent} moneda={this.props.proyectos?.find((p) => p.idProject === item.idProject).moneda} estado={item.estado}/>;
      case 'devengamientoPlanificado':
        return <CurrencyCell value={fieldContent} moneda={this.props.proyectos?.find((p) => p.idProject === item.idProject).moneda} estado={item.estado}/>;
      default: 
        return <DefaultCell value={fieldContent} estado={item.estado}/>
    }
  }
}
