import React from 'react';
import { IconButton } from '@fluentui/react/lib/Button';
import './collapseButton.css';

interface Props {
  onClick: () => void;
  collapsed: boolean;
}

export default function CollapseButton(props: Props) {
  const _onClick = () => {
    props.onClick();
  };
  const iconClassName = !props.collapsed ? 'iconCollapsed' : 'icon-normal';
  return <IconButton style={{ width: '36px' }} iconProps={{ iconName: 'ChevronRightMed', className: iconClassName }} onClick={_onClick}/>;
}
