import React from 'react';
import './index.css';
import RouterApp from './routes/RouterApp';
import useFirebaseUser from './hooks/useFirebaseUser';

export default function App() {
  const [user, loading] = useFirebaseUser();
  
  if (loading === false) {
    return <RouterApp user={user} />
  }
  return null;
}