import moment from 'moment';
import React from 'react';
import DefaultCell from './DefaultCell';
import ClosedProjectButton from './ClosedProjectButton';

interface FechaUltimaCertificacionCellProps {
    value: any;
    estado?: string;
}

export default function FechaUltimaCertificacionCell(props: FechaUltimaCertificacionCellProps){
    return (
        <>
            <DefaultCell 
            className='cell-normal-container'
            value={moment(props.value).isSameOrAfter('2020', 'year') && props.estado ? moment(props.value).format('DD-MM-YYYY') : ''}/>
        </>
    );
}