import { useEffect } from 'react';
import firebase from 'firebase';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, setUser, setUserLoading } from '../redux/actions/usuario/index';

export default function useFirebaseUser() {
  
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.generalReducer.user);
  const loadingUser = useSelector((state: any) => state.generalReducer.loadingUser);

  useEffect(() => {
    const _deleteAuthStateListener = firebase
      .auth()
      .onAuthStateChanged((user:any) => {
        if(user){
            dispatch(getUserData(user));
        }else{
            dispatch(setUser(null));
            dispatch(setUserLoading(false));
        }
        
      });
    return _deleteAuthStateListener;
  }, []);

  return [user, loadingUser];
}
