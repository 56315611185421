import React, { useEffect, useState } from 'react';
import './IntegracionButton.css';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Paper, Radio, RadioGroup, styled, Table } from '@mui/material';
import RangePicker from '../../RangePicker/RangePicker';
import SelectorPeriodo from '../../FormularioReporte/SelectorPeriodo';
import TablaIntegracion from '../TablaIntegracion/TablaIntegracion';
import { DataGrid, GridColDef, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import IProyecto from '../../../interfaces/IProyecto';
import SelectorPeriodoIntegracion from '../SelectorPeriodoIntegracion/SelctorPeriodoIntegracion';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import weekOfYear from 'dayjs/plugin/weekOfYear'; // Plugin alternativo
import { sendIntegracionThunk } from '../../../core/planificacionClient';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import IntegracionStatus from '../../IntegracionStatus/IntegracionStatus';
import ITablaIntegracion from '../../../interfaces/ITablaIntegracion';
import ISemana from '../../../interfaces/ISemana';
import WarningValidationModal from '../../WarningValidationModal/WarningValidationModal';

dayjs.extend(weekOfYear);

interface Props{
  proyecto: IProyecto;
  semana: ISemana;
}

interface IIntegracion{
  monto: number,
  fecha: string,
  horas: number,
  mes: number,
  anio: number
}

interface IRow{
  id: string,
  fecha: string,
  monto: number,
  horas: number,
  mes: number,
  anio: number
}

interface ICertificadoIntegracion{
  fecha: string,
  mes: number,
  anio: number,
  idProyecto: string
}

const ERPintegracionButton: React.FC<Props> = (props: Props) => {
  const integracionInitialValue = [{
    fecha: '',
    anio: null,
    mes: null,
    monto: 0,
    horas: 0
  }];

  const initialTableRows = [
    { id: 1, estado: 1, descripcion: '', nCertificacion: "CERTVTA - 37", 
      fecha: "2024-01-01", anio: 2024, mes: 1, monto: 2000, horas: 123, 
      fechaIntegracion: "2024-07-26", usuario: "Silvia Quinteros"},
  ];

  let defaultStartDate = props?.semana?.primerDia.format('YYYY-MM-DD').toString();
  let defaultEndDate = dayjs(defaultStartDate).add(4, 'day').format('YYYY-MM-DD')
  let defaultFixedDate = 'Personalizado';
  
  // UseStates
  const [open, setOpen] = useState(false);
  const [fechasFijas, setFechasFijas] = useState(defaultFixedDate);
  const [fechasDesde, setFechasDesde] = useState(defaultStartDate);
  const [fechasHasta, setFechasHasta] = useState(defaultEndDate);
  const [selectedValidationStatus, setSelectedValidationStatus] = useState("NO_VALIDADO");
  const [rowsSelected, setRowsSelected] = useState<any>([]);
  const [integracion, setIntegracion] = useState<IIntegracion[]>(integracionInitialValue);
  const [acumulado, setAcumulado] = useState<IIntegracion>({
    fecha: null,
    anio: null,
    mes: null,
    monto: 0,
    horas: 0
  });
  const [proyecto, setProyecto] = React.useState<IProyecto>(null);
  const [rows, setRows] = React.useState<IRow[]>(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [tableRows, setTableRows] = useState(initialTableRows);
  const [openWarningValidationModal, setOpenWarningValidationModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const loaderIntegracion:boolean = useSelector((state: RootStateOrAny) => state.generalReducer.sendingIntegracion);
  const integracionSuccess:boolean = useSelector((state: RootStateOrAny) => state.generalReducer.integracionSuccess);
  const listaIntegraciones:any = useSelector((state: RootStateOrAny) => state.generalReducer.integraciones);

  const dispatch = useDispatch();

  const handleAcumuladoInfo = (selection: Array<any>) => {
    let nuevoAcumuladoIntegracion = [];
  
    selection.forEach((id) => {
      const row = rows.find((row) => row.id === id);
      if (row) {
        const acumuladoItem = {
          monto: row.monto,
          horas: row.horas,
          fecha: row.fecha,
          anio: row.fecha.split('-')[0],
          mes: row.fecha.split('-')[1]
        };
        nuevoAcumuladoIntegracion.push(acumuladoItem);
      }
    });
  
    setIntegracion(nuevoAcumuladoIntegracion);
  
    let nuevoAcumulado = {
      monto: 0,
      horas: 0,
      fecha: '',
      mes: null,
      anio: null
    };
  
    nuevoAcumuladoIntegracion.forEach(i => {
      nuevoAcumulado.fecha = i.fecha;
      nuevoAcumulado.anio = i.fecha.split('-')[0];
      nuevoAcumulado.mes = i.fecha.split('-')[1];
      nuevoAcumulado.monto += i.monto;
      nuevoAcumulado.horas += i.horas;
    });
  
    setAcumulado(nuevoAcumulado);
  };

  function generateQuarters(year) {
    return {
      q1: {
        start: dayjs(`${year}-01-01`),
        end: dayjs(`${year}-03-31`)
      },
      q2: {
        start: dayjs(`${year}-04-01`),
        end: dayjs(`${year}-06-30`)
      },
      q3: {
        start: dayjs(`${year}-07-01`),
        end: dayjs(`${year}-09-30`)
      },
      q4: {
        start: dayjs(`${year}-10-01`),
        end: dayjs(`${year}-12-31`)
      }
    };
  }

  function getQuarter(date) {
    const year = dayjs(date).year();
    const quarters = generateQuarters(year);
  
    if (dayjs(date).isBetween(quarters.q1.start, quarters.q1.end, 'day', '[]')) {
      return { current: quarters.q1, previous: generateQuarters(year - 1).q4 };
    } else if (dayjs(date).isBetween(quarters.q2.start, quarters.q2.end, 'day', '[]')) {
      return { current: quarters.q2, previous: quarters.q1 };
    } else if (dayjs(date).isBetween(quarters.q3.start, quarters.q3.end, 'day', '[]')) {
      return { current: quarters.q3, previous: quarters.q2 };
    } else if (dayjs(date).isBetween(quarters.q4.start, quarters.q4.end, 'day', '[]')) {
      return { current: quarters.q4, previous: quarters.q3 };
    }
  }

  function getPreviousQuarter(date) {
    return getQuarter(date).previous.start;
  }

  function groupByWeek(certificadosHoras, certificadosDevengamiento): IRow[] {
    const weeksMap = new Map<string, IRow>();
  
    const addToWeeksMap = (fecha, horas = 0, devengado = 0) => {
      const week = dayjs(fecha).week();
      const year = dayjs(fecha).year();
      const month = dayjs(fecha).month()+1;
      const weekKey = `${year}-W${week}`;
  
      if (!weeksMap.has(weekKey)) {

        weeksMap.set(weekKey, {
          id: weekKey,
          fecha: fecha,
          monto: 0,
          horas: 0,
          anio: year,
          mes: month
        });
      }
  
      const currentRow = weeksMap.get(weekKey);
      if (currentRow) {
        currentRow.horas += horas;
        currentRow.monto += devengado;
      }
    };
  
    certificadosHoras?.forEach(certificado => {
      addToWeeksMap(certificado.fecha, certificado.horas);
    });
  
    certificadosDevengamiento?.forEach(certificado => {
      addToWeeksMap(certificado.fecha, 0, certificado.devengado);
    });
  
    // Redondear las horas a dos decimales
    const result = Array.from(weeksMap.values()).map(row => ({
      ...row,
      horas: parseFloat(row.horas.toFixed(2)) // Redondear a dos decimales
    }));

    return result;
  }

  function getRows(desde, hasta) {
    const desdeDate = dayjs(desde);
    const hastaDate = dayjs(hasta);
  
    const certificadosDevengamiento = proyecto?.certificados?.certificadosDevengamiento?.filter(certificado => {
      const fecha = dayjs(certificado.fecha);
      return fecha.isBetween(desdeDate, hastaDate, 'day', '[]');
    });
    
    const certificadosHoras = proyecto?.certificados?.certificadosHoras?.filter(certificado => {
      const fecha = dayjs(certificado.fecha);
      return fecha.isBetween(desdeDate, hastaDate, 'day', '[]');
    });

    const result = groupByWeek(certificadosHoras, certificadosDevengamiento);
    
    setRows(result);
  }

  const today = dayjs(); // Obtenemos la fecha actual con dayjs
  const currentYear = today.year(); // Obtenemos el año actual
  // const actualQuarters = generateQuarters(currentYear);

  useEffect(()=>{
    setProyecto(props?.proyecto)
  }, [props?.proyecto]);

  useEffect(()=>{
    handleAcumuladoInfo(rowsSelected)
  }, [rowsSelected])

  useEffect(() => {
    getRows(fechasDesde, fechasHasta);
  }, [fechasDesde, fechasHasta]);


  useEffect(()=>{
    setTableRows({...listaIntegraciones});
  },[listaIntegraciones])

  useEffect(() => {
    if (props?.semana?.primerDia) {
        const newStartDate = props.semana.primerDia.format('YYYY-MM-DD').toString();
        const newEndDate = dayjs(newStartDate).add(4, 'day').format('YYYY-MM-DD');
        setFechasDesde(newStartDate);
        setFechasHasta(newEndDate);
    }
}, [props.semana]);

  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleFechasFijas = (event: string) => {
    if (event === 'Personalizado') return;

    setFechasFijas(event);

    let desde = '';
    let hasta = dayjs().format('YYYY-MM-DD');

    switch (event) {
        case 'Última certificación':
            desde = props?.proyecto?.certificados?.certificadosHoras?.slice(-1)[0]?.fechaCertificacion || fechasDesde;
            break;
        case 'Semana actual':
            desde = dayjs().startOf('week').format('YYYY-MM-DD');
            break;
        case 'Mes actual':
            desde = dayjs().startOf('month').format('YYYY-MM-DD');
            break;
        case 'Trimestre actual':
            desde = getQuarter(today).current.start.format('YYYY-MM-DD');
            break;
        case 'Trimestre anterior':
            desde = getPreviousQuarter(today).format('YYYY-MM-DD');
            break;
        default:
            desde = dayjs().subtract(1, 'month').format('YYYY-MM-DD');
    }

    if (desde !== fechasDesde || hasta !== fechasHasta) {
        setFechasDesde(desde);
        setFechasHasta(hasta);
    }
  };

  const handleOption = (value: string) => {
    setSelectedValidationStatus(value);
  };

  const handleSelectionChange = (selection: GridRowId[]) => {
    setRowsSelected(selection)
  };

  const sendDevengamientoCertificado = async () =>{
    const data = buildBodyIntegracion(integracion, "", selectedValidationStatus, proyecto.idProject);
    dispatch(sendIntegracionThunk(data));
  }

  const handleSelectedOption = () =>{
    if(selectedValidationStatus === "CERTIFICADOS_POR_CLIENTE"){
      setOpenWarningValidationModal(true);
    }
    else{
      sendDevengamientoCertificado();
    }
  }

  // Construyo el body de la Integración

  const buildBodyIntegracion = (int: IIntegracion[], descripcion: string, validacion: string, idProyecto: string) =>{
    let integraciones = [];
    
    integraciones = int.map(i => {
      return {
        fecha: i.fecha,
        mes: i.mes,
        anio: i.anio,
        idProyecto: idProyecto
      }
    });

    const body = {
      descripcion: descripcion,
      estadoValidacionKEY: validacion,
      integraciones: integraciones
    }

    return body;
  }

  const buildIntegrationToShow = (data:ITablaIntegracion) =>{
    
    return;
  }
  
  // Estilo para ocultar el header
  const StyledDataGrid = styled(DataGrid)({
    '.MuiDataGrid-root': {
      height: 'auto'
    }
  });

  
  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'fecha',
      headerName: '',
      width: 300,
      editable: false,
      renderCell: (params) =>{
        return(
          <h4><b>Período: </b>{params.value?.split('T')[0]}</h4>
        )
      },
    },
    {
      field: 'monto',
      headerName: '',
      width: 300,
      editable: false,
      renderCell: (params) =>{
        return(
          <h4><b>Monto Certificado: </b>${params.value}</h4>
        )
      },
    },
    {
      field: 'horas',
      headerName: '',
      width: 300,
      editable: false,
      renderCell: (params) => {
        return(
          <h4><b>Horas Certificadas: </b>{params.value}hs</h4>
        )
      },
    },
  ];

  return (
    <>
      <button className='erp-integracion-button' 
        // onClick={handleOpen}
      >
        Integracion ERP
        <div className='erp-integracion-button-img'>
          {/* <img src='https://firebasestorage.googleapis.com/v0/b/certificacion-de-horas.appspot.com/o/finnegans.png?alt=media&token=7afe5682-b2b7-41df-97f8-6bcf4c82b72b'/> */}
        </div>
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className='integracion-modal'
      >
        <div className='integracion-container'>
          <div className='integracion-header'>
            <div className='integracion-header-one'>
              <h1><b>Integración Certificacion Horas ERP</b> {proyecto?.nombre == null ? "" : proyecto?.nombre}</h1>
            </div>
            <div className='integracion-header-two'>
              <CloseIcon sx={{color: '#be0411', fontSize: 40}} className='icon-close' onClick={handleClose}/>
            </div>
          </div>
          <div className='integracion-body'>
            <div className='integracion-date-picker'>
              <SelectorPeriodoIntegracion value={fechasFijas} onChange={handleFechasFijas}/>
              <RangePicker 
                valueDesde={fechasDesde} 
                valueHasta={fechasHasta} 
                onChangeFecha={([newDesde, newHasta]) => {
                  setFechasDesde(dayjs(newDesde)?.format('YYYY-MM-DD'));
                  setFechasHasta(dayjs(newHasta)?.format('YYYY-MM-DD'));
                }}
              />
              <div className='integracion-plan-view'>
                <h4>Plan de facturación asociado:</h4>
                <h5>PDVTA - 19</h5>
              </div>
            </div>
            <h2>Devengamiento certificado no integrado</h2>
            <div className='integracion-middle-container'>
              <div className='integracion-week-selector'>
                <StyledDataGrid
                    rows={rows}
                    columns={columns}
                    hideFooter={true}
                    pageSizeOptions={[0]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowSelectionModel={rowsSelected}
                    onRowSelectionModelChange={(selection: GridRowId[])=> handleSelectionChange(selection)}
                />
              </div>
              <div className='integracion-buttons-container'>
                <FormControl>
                  <FormLabel id='integracion-radio-group'><h4>Los datos enviados a continuación:</h4></FormLabel>
                  <RadioGroup value={selectedValidationStatus} onChange={(e) => handleOption(e.target.value)}>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      <FormControlLabel value="NO_VALIDADO" control={<Radio/>} onClick={()=> handleOption("NO_VALIDADO")} label="No han sido validados aún."/>
                      <FormControlLabel value="CORROBORADOS_POR_PM" control={<Radio/>} onClick={()=> handleOption("CORROBORADOS_POR_PM")} label="Han sido corroborados por el PM."/>
                      <FormControlLabel value="CERTIFICADOS_POR_CLIENTE" control={<Radio/>} onClick={()=> handleOption("CERTIFICADOS_POR_CLIENTE")} label="Han sido certificados por el cliente."/>
                      <button
                        className='integracion-enviar-button'
                        style={{
                          margin: "1rem 0 0 0",
                          padding: "1rem 0",
                          fontSize: "16px",
                          backgroundColor: selectedValidationStatus !== "NO_VALIDADO" ? "#017BE1" : "#cdcdcd", 
                          color: selectedValidationStatus !== "NO_VALIDADO" ? "#fff" : "#666", 
                          fontWeight: "700", 
                          border: "2px solid #ccc",
                          borderRadius: '3px',
                          cursor: selectedValidationStatus !== "NO_VALIDADO" ? "pointer" : "not-allowed",
                        }}
                        disabled={selectedValidationStatus === "NO_VALIDADO"}
                        onClick={()=> handleSelectedOption()}
                      >
                        Enviar devengamiento certificado a ERP
                      </button>
                    </Box>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <Divider sx={{padding: ".5rem"}}/>
            <TablaIntegracion
              tabla={tableRows}
              actuales={acumulado}
            />
          </div>
        </div>
      </Modal>
      <IntegracionStatus 
        open={openStatusModal}
        setOpen={setOpenStatusModal}
        success={integracionSuccess}
        loader={loaderIntegracion}
      />
      <WarningValidationModal
        open={openWarningValidationModal}
        setOpen={setOpenWarningValidationModal}
        send={sendDevengamientoCertificado}
      />
    </>
  );
}

export default ERPintegracionButton