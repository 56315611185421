import IProyecto from './../../../interfaces/IProyecto';
import IRecurso from './../../../interfaces/IRecurso';
import { ITableItemBare } from './../TablaBare/TablaBare';
import { IGroup } from '@fluentui/react/lib/DetailsList';
import IMes from './../../../interfaces/IMes';
import moment from 'moment';
import ISemanaCertificada from '../../../interfaces/ISemanaCertificada';
import IDiaCertificado from '../../../interfaces/IDiaCertificado';
import IPlanificacion from '../../../interfaces/IPlanificacion';



export const createTotal = (items: ITableItemBare[][]) => {
  const groupedItems = {};
  const totalItems = [];
  items.forEach(itemGroup => {
    if (itemGroup) {
      itemGroup?.forEach(item => {
        if(item?.colaborador){
          if (groupedItems[item.colaborador]) {
            groupedItems[item.colaborador].push({ ...item });
          } else {
            groupedItems[item.colaborador] = [{ ...item }];
          }
        }
      });
    }
  });

  for (let key of Object.keys(groupedItems)) {
    if (groupedItems[key].length > 1) {
      totalItems.push(groupedItems[key].reduce((acumulado: ITableItemBare, item) => {
        if (acumulado) {
          acumulado.groupKey = 'proyecto';
          acumulado.devengamientoActual += item.devengamientoActual;
          acumulado.devengamientoBaseline += item.devengamientoBaseline;
          acumulado.devengamientoEac += item.devengamientoEac;
          acumulado.devengamientoRemaining += item.devengamientoRemaining;
          acumulado.horasActual += item.horasActual;
          acumulado.horasBaseline += item.horasBaseline;
          acumulado.horasEac += item.horasEac;
          acumulado.horasRemaining += item.horasRemaining;
          return acumulado;
        } else {
          return { ...item, groupKey: 'proyecto' };
        }
      }));
    } else {
      totalItems.push({ ...groupedItems[key][0], groupKey: 'proyecto' });
    }
  }
  return totalItems;
}


export const createItemsMeses = (
  proyecto: IProyecto,
  mesActual: IMes,
  meses: Array<IMes>,
  certificadosHoras: Array<IDiaCertificado>,
  certificadosDevengamiento: Array<ISemanaCertificada>
): Array<ITableItemBare> => {
  if (!proyecto || !proyecto.planificacion) return [];
  const mesesOrdenados = meses.filter((m) => (m.key !== mesActual.key ? true : m.year !== mesActual.year));
  let items = [];
  mesesOrdenados.forEach((m) => {
    items.push(createItemsMes(proyecto, m, false, certificadosHoras, certificadosDevengamiento));
  });
  return items;
};

export const createItemsMesActual = (
  proyecto: IProyecto,
  mesActual: IMes,
  certificadosHoras: Array<IDiaCertificado>,
  certificadosDevengamiento: Array<ISemanaCertificada>
): Array<ITableItemBare> => {
  if (!proyecto || !proyecto.planificacion) return [];
  let items = createItemsMes(proyecto, mesActual, true, certificadosHoras, certificadosDevengamiento);
  return items;
};

const createItemsMes = (proyecto: IProyecto, mes: IMes, mesActual: boolean, certificadosHoras, certificadosDevengamiento): Array<ITableItemBare> => {
  const recursos = proyecto?.recursos;
  const items: Array<ITableItemBare> = [];
  recursos.forEach((r) => {
    items.push(getMesBareData(r.legajo, proyecto, mes, mesActual, certificadosHoras, certificadosDevengamiento));
  });
  return items;
};

// const getRecursos = (asignaciones: Array<IPlanificacion>) => {
//   const recursos: Array<IRecurso> = [];
//   asignaciones.forEach((a) => {
//     if (recursos.some((r) => r.legajo === a.recurso.legajo) === false) {
//       recursos.push(a.recurso);
//     }
//   });
//   return recursos;
// };

const getMesBareData = (
  legajo,
  proyecto: IProyecto,
  mes: IMes,
  mesActual: boolean,
  certificadosHoras: Array<IDiaCertificado>,
  certificadosDevengamiento: Array<ISemanaCertificada>
): ITableItemBare => {
  let bareData: ITableItemBare = null;
  const planificacion = proyecto?.planificacion?.filter((planif) => planif.legajo === legajo);
  const recursos = proyecto?.recursos;
  planificacion.filter(createFilterPlanificacionByMes(mes)).forEach((planif) => {
    const recurso = recursos.find(r => r.legajo === planif.legajo);
    if (bareData === null) {
      const devengamientoParametro = proyecto.certificados.certificadosDevengamiento.filter((c) => {
        const fechaDevengamiento = moment(c.fecha);
        return (
          (c.mes === mes.key && fechaDevengamiento.year() === mes.year) ||
          (fechaDevengamiento.isoWeek() === moment(`${mes.year}-${mes.key + 1}-01`).isoWeek() &&
            fechaDevengamiento.year() <= mes.year)
        );
      });
      bareData = <ITableItemBare>{
        colaborador: recurso.nombre,
        horasBaseline: planif.horasPromedio,
        horasActual: mesActual ? getHorasActualMesActualByLegajo(
          legajo,
          proyecto.certificados.certificadosHoras.filter(
            (c) =>
              moment(c.fecha).month() === moment(mes.value, 'MMMM YYYY').month() &&
              moment(c.fecha).year() === moment(mes.value, 'MMMM YYYY').year()
          ),
          certificadosHoras.filter((c) => moment(c.fecha).month() === mes.key && moment(c.fecha).year() === mes.year)
        ) : getHorasActualByLegajo(
          legajo,
          proyecto.certificados.certificadosHoras.filter(
            (c) =>
              moment(c.fecha).month() === moment(mes.value, 'MMMM YYYY').month() &&
              moment(c.fecha).year() === moment(mes.value, 'MMMM YYYY').year()
          )
        ),
        horasRemaining: 0,
        horasEac: 0,
        devengamientoActual: getDevengadoActualByLegajo(
          legajo,
          devengamientoParametro,
          certificadosDevengamiento.filter((c) => c.mes === mes.key && moment(c.fecha).year() === mes.year),
          mes
        ),
        devengamientoRemaining: 0,
        devengamientoBaseline: 0,
        devengamientoEac: 0,
        groupKey: mes.value,
        ptm: recurso.ptm,
      };
      bareData.devengamientoBaseline = bareData.horasBaseline * planif.valorHora;
      bareData.devengamientoEac = bareData.devengamientoBaseline;
      bareData.horasEac = bareData.horasBaseline;
      bareData.horasRemaining = bareData.horasBaseline - bareData.horasActual;
      bareData.devengamientoRemaining = bareData.devengamientoBaseline - bareData.devengamientoActual;
    } else {
      const horasB = planif.horasPromedio;
      const devB = horasB * planif.valorHora;
      bareData.horasBaseline = bareData.horasBaseline + horasB;
      bareData.horasEac = bareData.horasBaseline;
      bareData.devengamientoBaseline = bareData.devengamientoBaseline + devB;
      bareData.horasRemaining = bareData.horasBaseline - bareData.horasActual;
      bareData.devengamientoRemaining = bareData.devengamientoBaseline - bareData.devengamientoActual;
    }
  });
  return bareData;
};

const createTotalProyecto = (proyectoGroup) => {
  if (proyectoGroup.length === 0) {
    return {};
  } else if (proyectoGroup.length < 2) {
    return { ...proyectoGroup[0], colaborador: 'Total Proyecto' };
  }

  return proyectoGroup.reduce((prev: any, current: any) => {
    if (prev === undefined || prev === null) {
      return { ...current, colaborador: 'Total Proyecto' };
    }
    return {
      colaborador: 'Total Proyecto',
      horasBaseline: prev.horasBaseline + current.horasBaseline,
      horasActual: prev.horasActual + current.horasActual,
      horasRemaining: prev.horasRemaining + current.horasRemaining,
      horasEac: prev.horasEac + current.horasEac,
      devengamientoActual: prev.devengamientoActual + current.devengamientoActual,
      devengamientoBaseline: prev.devengamientoBaseline + current.devengamientoBaseline,
      devengamientoRemaining: prev.devengamientoRemaining + current.devengamientoRemaining,
      devengamientoEac: prev.devengamientoEac + current.devengamientoEac,
    };
  });
};

const createTotalesMeses = (items: ITableItemBare[][], meses: Array<IMes>): Array<ITableItemBare> => {
  const totalesMeses = [];
  const filteredItems = items?.map(i => i?.filter(x => x != null));

  for (const mes of meses) {
    let rows = filteredItems.find((i) => i?.[0]?.groupKey === mes.value);
    let total;

    if (rows) {
      rows = Array.from(rows);

      if (rows.length === 1) {
        total = { ...rows[0], colaborador: mes.value };
        totalesMeses.push(total);
      }

      if (rows.length > 1) {
        total = rows.reduce(
          (prev: ITableItemBare, current: ITableItemBare) => {
            if (!prev) return current; 
            if (!current) return prev; 

            return {
              colaborador: mes.value,
              horasBaseline: (prev.horasBaseline ?? 0) + (current.horasBaseline ?? 0),
              horasActual: (prev.horasActual ?? 0) + (current.horasActual ?? 0),
              horasRemaining: (prev.horasRemaining ?? 0) + (current.horasRemaining ?? 0),
              horasEac: (prev.horasEac ?? 0) + (current.horasEac ?? 0),
              devengamientoActual: (prev.devengamientoActual ?? 0) + (current.devengamientoActual ?? 0),
              devengamientoBaseline: (prev.devengamientoBaseline ?? 0) + (current.devengamientoBaseline ?? 0),
              devengamientoRemaining: (prev.devengamientoRemaining ?? 0) + (current.devengamientoRemaining ?? 0),
              devengamientoEac: (prev.devengamientoEac ?? 0) + (current.devengamientoEac ?? 0),
            };
          },
          {
            colaborador: mes.value,
            horasBaseline: 0,
            horasActual: 0,
            horasRemaining: 0,
            horasEac: 0,
            devengamientoActual: 0,
            devengamientoBaseline: 0,
            devengamientoRemaining: 0,
            devengamientoEac: 0,
          }
        );
        totalesMeses.push(total);
      }
    }
  }

  return totalesMeses;
};

export const createGroups = (
  items: ITableItemBare[][],
  mesActual: IMes,
  meses: Array<IMes>,
  collapsedKeys: any
): Array<IGroup> | any => {
  if (!mesActual) return;
  const filteredItems = items?.map(i => i?.filter(x => x != null));
  const proyectoTotal = createTotalProyecto(filteredItems[0]);
  const totalesMeses = createTotalesMeses(filteredItems, meses);
  const groups: Array<IGroup> | any = [
    {
      key: 'proyecto',
      name: 'Total proyecto',
      startIndex: 0,
      count: filteredItems[0].length,
      item: proyectoTotal,
      isCollapsed: true,
    },
    {
      key: mesActual.value,
      name: mesActual.value,
      startIndex: filteredItems[0].length,
      count: filteredItems[1] && filteredItems[1].length,
      item: totalesMeses.find((t) => t.colaborador === mesActual.value),
      isCollapsed: true,
    },
  ];

  totalesMeses
    .filter((t) => t.colaborador !== mesActual.value)
    .forEach((t) => {
      const lastGroup: any = groups[groups.length - 1];

      groups.push({
        key: t.colaborador,
        name: t.colaborador,
        startIndex: lastGroup.startIndex + lastGroup.count,
        count: filteredItems.find((i) => i && (i.length !== 0 ? i[0]?.groupKey : false) === t.colaborador).length,
        item: t,
        isCollapsed: true,
      });
    });
  return groups;
};

const getHorasActualMesActualByLegajo = (legajo, horasCertificadas: Array<IDiaCertificado>, certificadosNuevos: Array<IDiaCertificado>) => {
  horasCertificadas = horasCertificadas.filter((c) => c.legajo === legajo);
  certificadosNuevos = certificadosNuevos.filter((c) => c.legajo === legajo);
  const certificados = [];
  horasCertificadas.forEach((hc) => {
    const i = certificadosNuevos.findIndex((c) => c.valido !== false && moment(hc.fecha).isSame(c.fecha));
    if (i >= 0) {
      certificados.push(certificadosNuevos[i]);
    } else {
      certificados.push(hc);
    }
  });

  certificadosNuevos.forEach((cn) => {
    const i = horasCertificadas.findIndex((c) => c.valido !== false && moment(cn.fecha).isSame(c.fecha));
    if (i < 0) {
      certificados.push(cn);
    }
  });

  if (certificados.length > 0) {
    return certificados.map((c) => c.horas).reduce((a, b) => a + b);
  } else {
    return 0;
  }
};

const getHorasActualByLegajo = (legajo, horasCertificadas: Array<IDiaCertificado>) => {
  horasCertificadas = horasCertificadas.filter((c) => c.legajo === legajo);
  if (horasCertificadas.length > 0) {
    return horasCertificadas.map((c) => c.horas).reduce((a, b) => a + b);
  } else {
    return 0;
  }
};

const getDevengadoActualByLegajo = (legajo, devengamientoCertificado: Array<ISemanaCertificada>, certificadosNuevos,mes : IMes) => {
  devengamientoCertificado = devengamientoCertificado.filter((c) => c.legajo === legajo && c.mes === mes.key && moment(c.fecha).year() === mes.year);
  certificadosNuevos = certificadosNuevos.filter((c) => c.legajo === legajo);

  const certificados = [];
  devengamientoCertificado.forEach((hc) => {
    const i = certificadosNuevos.findIndex((c) => c.valido === true && c.mes === hc.mes && moment(hc.fecha).isSame(moment(c.fecha), "date"));
    if (i >= 0) {
      certificados.push(certificadosNuevos[i]);
    } else {
      certificados.push(hc);
    }
  });
  certificadosNuevos.forEach((cn) => {
    const i = devengamientoCertificado.findIndex(
      (c) => c.mes === cn.mes && moment(cn.fecha).isSame(moment(c.fecha), "date")
    );
    if (i < 0) {
      certificados.push(cn);
    }
  });
  if (certificados.length > 0) {
    return certificados.map((c) => c.devengado).reduce((a, b) => a + b);
  } else {
    return 0;
  }
};

const createFilterPlanificacionByMes = (mes: IMes) => (planificacion: IPlanificacion) => {
  const fechaPlanificacion = planificacion?.fecha ? moment(planificacion.fecha) : null;
  if (!fechaPlanificacion) return false;
  return (
    mes.primerDia.isSame(fechaPlanificacion, 'day') ||
    mes.primerDia.clone().endOf('M').isSame(fechaPlanificacion, 'day') ||
    fechaPlanificacion.isBetween(mes.primerDia, mes.primerDia.clone().endOf('M'), 'day', '[]')
  );
};