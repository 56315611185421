import React from "react";
import PropTypes from "prop-types";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface Props {
  height?: number | string;
  width?: number | string;
  list: Array<{ value: string; id: string | number }>;  // Permite string o number
  selected: { value?: string; id: string | number };
  onChange?: (event: React.ChangeEvent<{ value: unknown }>, option: { id: string | number; value: string }) => void;
  label: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

function Selector({ height, width, list, selected, onChange, label, style, disabled }: Props): JSX.Element {
  const handleChange = (event: any) => {
    const selectedOption = list.find((item) => item.id === event.target.value);
    if (selectedOption && typeof onChange === "function") {
      onChange(event, { id: selectedOption.id, value: selectedOption.value });
    }
  };

  return (
    <FormControl
      sx={{
        minWidth: width || 250,
        height: height || 40,
        ...style,
      }}
      disabled={disabled}
    >
      <InputLabel
        sx={{
          marginTop: -1,
          fontSize: "14px",
        }}
        shrink
      >
        {label}
      </InputLabel>
      <Select
        value={selected?.id || ""}
        onChange={handleChange}
        displayEmpty
        sx={{
          height: 36,
          border: "none",
          backgroundColor: "transparent",
          fontSize: "14px",
        }}
      >
        {list.map((element) => (
          <MenuItem key={element.id} value={element.id} disabled={selected && selected.id === element.id}>
            {element.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

Selector.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  list: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Selector;
