import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import RouteForbbiden from './RouteForbbiden';
import RouteInicio from './RouteInicio';
import RouteLogin from './RouteLogin';
import RouteProyecto from './RouteProyecto';
import ReactGA from 'react-ga'

const TRACKING_ID = '332725183'
ReactGA.initialize(TRACKING_ID)

export default function RouterApp( { user } ){
  if(user != null){
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact={true} render={RouteInicio} />
          <Route path="/proyecto/:idProject?" render={RouteProyecto} />
          <Route path="/forbidden" render={RouteForbbiden} />
          <Route path="*" render={() => <Redirect to={"/"} />} />
        </Switch>
      </BrowserRouter>
    );
  }else{
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/Login" render={RouteLogin} />
          <Route  path="*" render={() => <Redirect to={"/Login"} />} />
        </Switch>
      </BrowserRouter>
    );
  }
}