import React from 'react'
import './Moneda.css'

const Moneda = ({moneda = "ars"}) => {
  return (
    <div className='moneda'>Moneda : <span>{moneda.toUpperCase()}</span></div>
  )
}


export default Moneda