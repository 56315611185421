/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { createListItems } from './logic';
import IDiaCertificado from './../../../interfaces/IDiaCertificado';
import './index.css';
import IMes from './../../../interfaces/IMes';
import { InputHoras } from './InputHoras/InputHoras';
import { buildColumns } from './config';
import { InputDevengado } from './InputDevengado/InputDevengado';
import ISemanaCertificada from './../../../interfaces/ISemanaCertificada';
import IProyecto from './../../../interfaces/IProyecto';
import { Moment } from 'moment';
import moment from 'moment';
import HorasTextField from './HorasTextField/HorasTextField';
import DevengamientoTextField from './DevengamientoTextField/DevengamientoTextField';
import useInputsRef from './../Hooks/useInputsRef';
import { AnimatedTimeText } from './../../AnimatedText/AnimatedTimeText';
import BaseTable from '../../BaseTable/BaseTable';
import ErrorStatus from '../../../interfaces/ErrorStatus';
import format from 'date-fns/format'
import IPlanificacion from '../../../interfaces/IPlanificacion';
import ICertificadoHoras from '../../../interfaces/ICertificadoHoras';
import { IntegradoField } from './IntegradoField/IntegradoField';


export interface ITableItem {
  colaborador: string;
  concepto: string;
  lunes: number | IDiaCertificado | any;
  martes: number | IDiaCertificado | any;
  miercoles: number | IDiaCertificado | any;
  jueves: number | IDiaCertificado | any;
  viernes: number | IDiaCertificado | any;
  totalHoras: number;
  horaAvg: number | string | any;
  montoTotal: number | any;
  legajo?: number;
  id: string;
  recursoActivo? : boolean;
  fechaDesvinculacion? : string;
  ptm? : boolean;
  sumados? : Array<Number | String>;
  integrado? : boolean;
}

interface Props {
  onClickProyecto?: (id: string) => void;
  planificacion: Array<IPlanificacion>;
  semana: any;
  show: boolean;
  mes: IMes;
  onChange: () => void;
  addCertificado: (c: ICertificadoHoras) => void;
  addCertificadoDevengamiento: (c: ISemanaCertificada) => void;
  certificadosDevengamiento: Array<ISemanaCertificada>;
  certificadosHoras: Array<IDiaCertificado>;
  idProject: string;
  proyecto: IProyecto;
  edit: boolean;
  error?:ErrorStatus;
  moneda:string|null;
  filtro?: string;
}

const TablaCertificacion: React.FC<Props> = (props: Props) => {
  const [inputsRef, addRef, focusNext, focusPrevious] = useInputsRef();
  const [devengadoEditableLegajos, setDevengadoEditableLegajos] = useState({});
  
  let items = createListItems(
    props.proyecto?.planificacion,
    props.proyecto,
    props.semana,
    props.mes,
    props.certificadosHoras,
    props.certificadosDevengamiento
  );

  items = items.filter(item => props.filtro === "Todos" || (props.filtro === "Snoopies" ? item.ptm === false : item.ptm === true))
  
  const projectName = `${props.proyecto?.cliente}_${props.proyecto?.nombre}`;

  const semanaCertificada = (legajo): boolean => {
    const certificadosHorasDB = props.proyecto.certificados.certificadosHoras;
    const primerDia: Moment = props.semana.primerDia.clone();
    const ultimoDia: Moment = primerDia.clone().add(4, 'days');
  
    for (; primerDia.isSameOrBefore(ultimoDia); primerDia.add(1, 'day')) {
      if ( 
        primerDia.month() === props.mes.key &&
        props.planificacion?.some(
          (p) => p.legajo === legajo && moment(p.fecha).isSame(primerDia, 'day')
        )
      ) {
        if (certificadosHorasDB.some((c) => c.legajo === legajo && primerDia.isSame(moment(c.fecha)))) {
          return true;
        }
  
        if (props.certificadosHoras.some((c) => c.valido === true && c.legajo === legajo && primerDia.isSame(moment(c.fecha)))) {
          return true;
        }
      }
    }
  
    return false;
  };  
  

  const showInputMonto = (legajo) => {
    return semanaCertificada(legajo) || devengadoEditableLegajos[legajo] || false;
  };

  const onInputHorasChange = (item: ITableItem, columnKey) => (val) => {
    props.onChange();
    props.addCertificado({
      fecha: item[columnKey].fecha,
      fechaCertificacion: new Date().toISOString(),
      horas: Number(val),
      legajo: item.legajo,
      idProject: props.idProject,
      fechaModificacion: item[columnKey].fechaModificacion,
      idCertificacion: item[columnKey].idCertificacion,
    });
  };

  const onInputDevengadoChange = (item: ITableItem, columnKey) => (newValue) => {
    props.onChange();
    props.addCertificadoDevengamiento({
      fecha: props.semana.primerDia.format('YYYY-MM-DD'),
      fechaCertificacion: new Date().toISOString(),
      devengado: Number(newValue),
      legajo: item.legajo,
      idProject: props.idProject,
      valido: newValue !== '' ? true : false,
      mes: props.mes.key,
      idCertificacion: item[columnKey].idCertificacion,
      moneda:props.moneda ? props.moneda : "ARS"
    });
  };

  const getLegajo = (nombre: string) =>{
    const filtroColaborador = items.filter(data => data.colaborador === nombre && data.concepto === 'Certificado')
    if(filtroColaborador !== undefined){
      if( filtroColaborador[0] !== undefined){
        return filtroColaborador[0].legajo
      }
    }
  }

  // Mapa para almacenar si un día es noCertificable según la planificación
  const noCertificableMap = new Map<string, boolean>();

  items.forEach((it) => {
    if (it.concepto === "Planificado") {
      ["lunes", "martes", "miercoles", "jueves", "viernes"].forEach((dayKey) => {
        const key = `${it.colaborador}-${dayKey}`;
        noCertificableMap.set(key, it[dayKey] === 0);
      });
    }
  });

  const _renderItemColumn = (item: ITableItem, index: number, column: IColumn | any) => {
    let fieldContent = item[column.fieldName as keyof ITableItem] as string | any;
    const recurso = props.proyecto.recursos.find((r) => r.nombre === item.colaborador);
  
    // Recuperamos el valor de noCertificable según el colaborador y el día
    const key = `${item.colaborador}-${column.key}`;
    const noCertificable = noCertificableMap.get(key) ?? false;
  
    if (column.key === "montoTotal" || (column.key === "horaAvg" && item.concepto !== "Trackeado")) {
      fieldContent = Number(fieldContent).toCurrency(props.proyecto.moneda);
    }
  
    if (column.key === "colaborador" && item.concepto !== "Planificado") {
      return <div className="cell-normal-proyecto" />;
    }
  
    if (item.concepto === "Certificado") {
      if (["lunes", "martes", "miercoles", "jueves", "viernes"].includes(column.key)) {
        return props.edit && props.mes.key === column.dia.month() ? (
          <div className={"cell-horas-proyecto-edit"}>
            <InputHoras
              onChange={onInputHorasChange(item, column.key)}
              mes={props.mes}
              reference={(ref) => addRef(ref)}
              semana={props.semana}
              column={column}
              suggestedValue={items[index - 1][column.key]}
              resource={{ activo: recurso.activo, fechaDesvinculacion: recurso.fechaEgreso }}
              focusNext={focusNext}
              focusPrevious={focusPrevious}
              defaultValue={item[column.fieldName].horas}
              noCertificable={noCertificable}
              onValueChange={(isEditable) => {
                setDevengadoEditableLegajos((prev) => ({
                  ...prev,
                  [item.legajo]: isEditable
                }));
              }}              />
          </div>
        ) : (
          <div className={'cell-horas-proyecto'}>
            <HorasTextField mes={props.mes} dia={item[column.fieldName].fecha} value={fieldContent} />
          </div>
        );
      } if (column.key === 'montoTotal') {
        return (
          <div className={!props.edit ? 'cell-devengamiento-proyecto' : 'cell-devengamiento-proyecto-edit'}>
            {props.edit ? (
              <InputDevengado
                onChange={onInputDevengadoChange(item, column.key)}
                enable={showInputMonto(item.legajo)}
                mes={props.mes}
                semana={props.semana}
                column={column}
                reference={(ref) => addRef(ref)}
                focusNext={focusNext}
                focusPrevious={focusPrevious}
                defaultValue={item[column.fieldName].devengado}
                moneda={props.proyecto.moneda}
              />
            ) : (
              <DevengamientoTextField value={item[column.fieldName].devengado} moneda={props.proyecto.moneda} />
            )}
          </div>
        );
      } if(column.key === 'integrado'){
        return(
          <div className="cell-devengamiento-proyecto">
            <IntegradoField
              mes={props.mes}
              semana={props.semana}
              column={column}
              isIntegrated={item.integrado}        
            />
          </div>
        );
      }
    }

    if (column.key === 'colaborador') {
      const ptm = item['ptm']? '(ptm)':'';
      return (
        <div className="cell-normal-proyecto">
          <i className="ptm">{ptm}</i><span>{fieldContent}</span>
        </div>
      );
    }

    if (column.key === 'concepto') {
      return (
        <div className="cell-normal-proyecto">
          <span>{fieldContent}</span>
        </div>
      );
    }

    if (column.key === 'horaAvg' || column.key === 'montoTotal') {
      return (
        <div className="cell-devengamiento-proyecto">
          <span>{fieldContent}</span>
        </div>
      );
    }
    if (column.key === 'totalHoras') {
      return (
        <div className="cell-horas-proyecto">
          <AnimatedTimeText value={Number(fieldContent)} />
        </div>
      );
    } 
    if(column.key === 'integrado'){
      return(
        <div className="cell-devengamiento-proyecto"></div>
      );
    }
    else {
      const color = props.mes.key === moment(column.dia).month() ? 'black' : '#cccccc';
      const title = props.mes.key === moment(column.dia).month() ? '' : 'No pertenece al mes de ' + props.mes.value;
      const fecha = format(new Date(column.dia._d), 'yyyy-MM-dd')
      const legajo = getLegajo(item.colaborador)
      const cargasToggl = props.proyecto?.cargasToggl;
      const cargasAsignacion = cargasToggl?.filter(carga => format(new Date(carga.inicio), 'yyyy-MM-dd') === fecha);

      return (
        <div className="cell-horas-proyecto">
          <AnimatedTimeText style={{ color }} title={title} value={Number(fieldContent)} concepto={item.concepto} edit={props.edit} idProject={props.idProject} fecha={fecha} legajo={Number(legajo)}/>
        </div>
      );
    }
  };

  return (
    <BaseTable
      projectName={projectName}
      showButtons={true}
      error={props.error}
      items={items.map(i => [i])}
      columns={buildColumns(props.semana)}
      onRenderItemColumn={_renderItemColumn}
      rowHeigth={"30px"}
      moneda={props.proyecto?.moneda}
      filtro={props.filtro}
      />
  );
};

export default TablaCertificacion;
