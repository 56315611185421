import React, { useState, useEffect, useRef } from 'react';
import { TextField } from 'office-ui-fabric-react';
import IMes from '../../../../interfaces/IMes';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import ISemana from '../../../../interfaces/ISemana';

interface Props {
  mes: IMes;
  semana: ISemana;
  column: IColumn;
  onChange?: (value: any) => void;
  enable: boolean;
  defaultValue: number;
  reference: (ref: React.Ref<any>) => void;
  focusNext: () => void;
  focusPrevious: () => void;
  moneda: string;
}

export const InputDevengado = (props: Props): JSX.Element => {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    props.reference(inputRef);
  }, []);

  useEffect(() => {
    if (props.defaultValue !== null) {
      setValue(props.defaultValue.toString());
    }
  }, [props.defaultValue]);

  const onFocus = () => {
    inputRef.current._isFocused = true;
    setFocused(true);
  };
  const onBlur = () => {
    inputRef.current._isFocused = false;
    setFocused(false);
    setTimeout( () => props.onChange(value) , 100)
  };

  const getRenderValue = () => {
    if (focused) {
      return value;
    } else {
      if (value === '') return '$';
      return Number(value).toCurrency(props.moneda);
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      inputRef.current.blur();
    }
    if (e.keyCode === 9) {
      if (e.shiftKey) {
        props.focusPrevious();
      } else {
        props.focusNext();
      }
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const onChange = (e, newVal: string) => {
    setValue(newVal.replace(',', '.').replace('$', ''));
  };

  return (
    <TextField
      borderless
      value={getRenderValue()}
      tabIndex={0}
      onFocus={onFocus}
      componentRef={inputRef}
      spellCheck={'false'}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      onChange={onChange}
      disabled={!props.enable}
      title={!props.enable === true ? 'Para certificar el devengamiento de la semana se deben certificar las horas de todos los días' : ''}
      styles={{
        fieldGroup: { height: 'auto', borderBottom: '1px', borderBottomColor: 'black' },
        field: { paddingLeft: 0 },
        root: { borderBottom: '1px solid black' },
      }}
      style={{ fontSize: '12px', fontWeight: 'bold' }}
    />
  );
};
